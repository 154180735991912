import React, { useEffect, useReducer } from 'react';

import Dimensions from '../../components/dimensions/dimensions';
import MarkersMap from '../../components/MarkersMap/zoom-test2.js';
import PoliciesOverlay, { TransitionPoliciesOverlay } from '../../components/PoliciesOverlay';
import QuerySelect from '../../components/query-select/query-select.jsx';
import { Filters, FiltersItem } from '../../components/Filters';
import PoliciesReadout from '../../components/PoliciesReadout';
import Tooltip from '../../components/Tooltip';
import Dashboard from '../../components/Dashboard';
import TabsContainer from '../../components/tabs/tabs';
import Tablist from '../../components/tablist/tablist';
import Tab, { UnconnectedTab } from '../../components/tab/tab';
import Tabpanel from '../../components/tabpanel/tabpanel';
import SolidWasteDashboard from '../../containers/SolidWasteDashboard';
import ElectronicWasteDashboard from '../../containers/ElectronicWasteDashboard';
import CountriesList, { CountriesListItem } from '../../components/CountriesList';
import { MapWrapper, MapWrapperMain, MapWrapperFooter } from '../../components/MapWrapper';
import PolicyMarkerLegend from '../../components/PolicyMarkerLegend';
import MapActions from '../../components/MapActions';
import IconButton from '../../components/icon-button/icon-button.jsx';
import ShareButton from '../../components/share-button/share-button.jsx';
import WelcomeModalButton from '../../components/WelcomeModalButton';
import PoliciesDescriptionsDashboard from '../../components/PoliciesDescriptionsDashboard';

import { matchBreakpoint } from '../../utilities/breakpoints.js';

import { reducer, getInitialState } from './reducer.js';
import * as selectors from './selectors.js';
import { stateToQuerystring } from './utils.js';

import './styles.scss';

export default function Policies(props) {
  // FIXME: getInitialState is called multiple times?
  const [state, dispatch] = useReducer(reducer, getInitialState(props.models));

  useEffect(() => {
    fetch(`${__config.policiesApiUrl}policies`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: 'RESULTS_LOADED', id: 'map', data: data });
      });

    window.addEventListener('popstate', () => {
      dispatch({ type: 'HISTORY_POPSTATE' });
    });

    const mql = matchBreakpoint('tablet');
    mql.addEventListener('change', (event) => {
      if (event.matches) {
        dispatch({ type: 'SET_ACTIVE_MOBILE_TAB', id: 'map' });
      }
    });
  }, [dispatch]);

  const handleHoverCountry = (id) => {
    dispatch({ type: 'SET_HOVERED_COUNTRY', id: id });
  };

  const handleClickCountry = (id) => {
    if (state.activeCountryId === id) {
      dispatch({ type: 'SET_OVERLAY_VISIBLE', visible: true });
      return;
    }

    dispatch({ type: 'SET_ACTIVE_COUNTRY', id: id });
    dispatch({ type: 'SET_OVERLAY_VISIBLE', visible: true });
    dispatch({ type: 'RESULTS_REQUESTED', id: 'country' });

    fetch(`${__config.policiesApiUrl}policies/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: 'RESULTS_LOADED', id: 'country', data: data });
      });
  };

  const handleSelectType = (type) => {
    dispatch({ type: 'SET_ACTIVE_TYPE', data: type });
  };

  const handleSelectYear = (year) => {
    dispatch({ type: 'SET_ACTIVE_YEAR', data: year });
  };

  const handleClickOverlayClose = () => {
    dispatch({ type: 'SET_OVERLAY_VISIBLE', visible: false });
  };

  const handleClickMobileTab = (id) => () => {
    dispatch({ type: 'SET_ACTIVE_MOBILE_TAB', id: id });
  };

  const handleSelectRegion = (region) => {
    dispatch({ type: 'SET_ACTIVE_REGION', data: region });
  };

  if (!state.types.data || !state.results.map.data) {
    return null;
  }

  const activeType = selectors.selectActiveType(state);
  const mapData = selectors.selectFormattedMapData(state);
  const listData = selectors.selectListData(state);
  const tooltipData = selectors.selectTooltipData(state);
  const countries = selectors.selectCountries(state);
  const countryData = selectors.selectCountryData(state);
  // const activeRegionCountries = selectors.selectActiveRegionCountries(state);
  const activeRegionCountriesWithData = selectors.selectActiveRegionCountriesWithData(state);
  const activeTypeHasDescription = selectors.selectActiveTypeHasDescription(state);

  const activeTypeBranch = state.types.data.getBranch(activeType.id);

  // console.log(
  //   'activeRegion',
  //   state.activeRegion,
  //   activeRegionCountries,
  //   activeRegionCountriesWithData
  // );

  return (
    <div className="policies-view">
      <Filters layout="policies">
        <FiltersItem>
          <QuerySelect
            id="exporter"
            label={__('policies-filter-policy')}
            current={state.activeType}
            data={state.types.data}
            showIcons
            inline
            handleSelect={handleSelectType}
          />
        </FiltersItem>
        <FiltersItem>
          <QuerySelect
            label={__('policies-filter-year')}
            current={state.activeYear}
            data={state.years}
            handleSelect={handleSelectYear}
          />
        </FiltersItem>
        <FiltersItem>
          <QuerySelect
            label={__('policies-filter-zoom')}
            current={state.activeRegion}
            data={state.regions}
            noWrap
            handleSelect={handleSelectRegion}
          />
        </FiltersItem>
      </Filters>
      <div className="policies-view__mobile-tabs">
        <Tablist>
          <UnconnectedTab
            active={state.activeMobileTab === 'map'}
            onClick={handleClickMobileTab('map')}
          >
            {__('policies-tab-map')}
          </UnconnectedTab>
          <UnconnectedTab
            active={state.activeMobileTab === 'list'}
            onClick={handleClickMobileTab('list')}
          >
            {__('policies-tab-list')}
          </UnconnectedTab>
        </Tablist>
      </div>
      <div class="policies-view__map">
        {state.activeMobileTab === 'map' && (
          <MapWrapper>
            <MapWrapperMain>
              <Dimensions>
                <MarkersMap
                  data={mapData}
                  countries={countries}
                  zoomCountries={activeRegionCountriesWithData}
                  onHoverCountry={handleHoverCountry}
                  onClickCountry={handleClickCountry}
                />
              </Dimensions>
            </MapWrapperMain>
            <MapWrapperFooter modifier="policies">
              <PolicyMarkerLegend policyType={activeType} />
              <MapActions status={__('policies-map-footnote')}>
                <WelcomeModalButton />
                <ShareButton queryString={stateToQuerystring(state)} />
                <IconButton
                  href={`${__config.policiesApiUrl}policies/download/`}
                  message={__config.twitterMessage}
                  icon="download"
                  kind="rounded"
                  download
                />
              </MapActions>
            </MapWrapperFooter>
          </MapWrapper>
        )}
        {state.activeMobileTab === 'list' && (
          <div className="policies-view__mobile-list">
            <CountriesList
              data={listData}
              renderItem={(datum) => {
                if (datum.children) {
                  return datum.model.name;
                } else {
                  return (
                    <CountriesListItem
                      title={datum.model.name}
                      count={datum.data.count}
                      colour={datum.data.colour}
                      highlighted={datum.data.highlighted}
                      onClick={() => handleClickCountry(datum.model.id)}
                    />
                  );
                }
              }}
            />
          </div>
        )}
      </div>
      <div className="policies-view__dashboard">
        {activeTypeHasDescription ? (
          <PoliciesDescriptionsDashboard type={activeTypeBranch} />
        ) : (
          <Dashboard
            tabs={
              <TabsContainer>
                <Tablist>
                  <Tab modifier="exporter">{__('waste-solid-tab')}</Tab>
                  <Tab modifier="exporter">{__('waste-electronic-tab')}</Tab>
                </Tablist>
                <Tabpanel>
                  <SolidWasteDashboard />
                </Tabpanel>
                <Tabpanel>
                  <ElectronicWasteDashboard />
                </Tabpanel>
              </TabsContainer>
            }
          />
        )}
      </div>
      {state.hoveredCountryId && tooltipData ? (
        <Tooltip>
          <PoliciesReadout
            country={state.hoveredCountry}
            policiesData={tooltipData.data}
            policiesTotal={tooltipData.total}
          />
        </Tooltip>
      ) : null}
      <TransitionPoliciesOverlay
        active={state.overlayVisible && !!state.activeCountry}
        country={state.activeCountry}
        policies={countryData}
        onClickClose={handleClickOverlayClose}
      />
    </div>
  );
}
