import React, { Component } from 'react';
import debounce from 'lodash/debounce';

export const DimensionsContext = React.createContext({
  width: null,
  height: null,
  trueWidth: null,
  trueHeight: null,
});

export default class Dimensions extends Component {
  constructor() {
    super();

    this.state = {
      width: null,
      height: null,
      trueWidth: null,
      trueHeight: null,
    };

    this.lastWindowWidth = null;

    this.debouncedHandleWindowResize = debounce(this.handleWindowResize, 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleWindowResize);

    if (!this.state.width || !this.state.height) {
      // TODO: Defer to RAF instead?
      // window.setTimeout(() => {
      this.measureDimensions();
      // }, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleWindowResize);
  }

  render(props, state) {
    if (!state.width || !state.height) {
      return <div />;
    }

    return (
      <DimensionsContext.Provider value={this.state}>
        <div>{props.children}</div>
      </DimensionsContext.Provider>
    );
  }

  handleWindowResize = () => {
    let windowWidth = document.body.clientWidth;

    if (this.lastWindowWidth && this.lastWindowWidth === windowWidth) {
      return;
    }

    this.measureDimensions();

    this.lastWindowWidth = windowWidth;
  };

  measureDimensions() {
    // Skip if component is not in DOM or hidden
    // if (!this.base || !this.base.offsetParent) {
    //     return;
    // }

    let rect = this.base.parentNode.getBoundingClientRect();
    let width = rect.width;
    let height = rect.height;
    let trueWidth = width;
    let trueHeight = height;

    if (this.props.minWidth && this.props.minHeight) {
      if (width < this.props.minWidth) {
        width = this.props.minWidth;
        height = this.props.minHeight;
      }
    }

    // TODO: Check if this is necessary
    if (
      this.state.width === width &&
      this.state.height === height &&
      this.state.trueWidth === trueWidth &&
      this.state.trueHeight === trueHeight
    ) {
      return;
    }

    this.setState({
      width: width,
      height: height,
      trueWidth: trueWidth,
      trueHeight: trueHeight,
    });
  }
}
