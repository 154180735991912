import React, { Component } from 'react';

import SharePopover from '../SharePopover';
import IconButton from '../icon-button/icon-button.jsx';

import './_share-button.scss';

export default class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentMouseDown);
    document.addEventListener('touchstart', this.handleDocumentMouseDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    document.removeEventListener('touchstart', this.handleDocumentMouseDown);
  }

  handleClick = () => {
    if (this.state.open) {
      return this.handleClose();
    }

    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDocumentMouseDown = (event) => {
    if (!this.base || !this.base.contains(event.target)) {
      this.handleClose();
    }
  };

  render(props, state) {
    return (
      <div className="share-button">
        <IconButton icon="share" kind="rounded" ariaExpanded={state.open} ariaLabel="Share" handleClick={this.handleClick} />
        {state.open ? (
          <SharePopover
            queryString={props.queryString}
            message={props.message}
            popoverAlign={props.popoverAlign}
            handleClose={this.handleClose}
          />
        ) : null}
      </div>
    );
  }
}
