if (process.env.NODE_ENV !== 'production') {
  require('preact/debug');
} else {
  require('preact/devtools');
}

import React from 'react';
import { render } from 'react-dom';
import fetch from 'unfetch';

// Webpack public path module must be imported first
import '../../publicpath.js';
import models from '../../utilities/models.js';
import Policies from '../../views/policies/index.js';
import { tweakPoliciesCountries, tweakPoliciesCountryGroups } from '../../utilities/tweakModels.js';

import '../../utilities/googleTranslateHack.js';

import '../../styles.js';

function load() {
  const preview = location.search.includes('preview=1');

  const modelsRequest = fetch(`${__config.api_path}models`).then((response) => {
    return response.json();
  });

  // TODO: Allow policy models to be inlined into host page
  const policiesModelsRequest = fetch(
    `${__config.policiesApiUrl}models?${preview ? 'preview=1' : ''}`
  ).then((response) => {
    return response.json();
  });

  Promise.all([modelsRequest, policiesModelsRequest]).then(([modelsData, policyModelsData]) => {
    init(modelsData, policyModelsData);
  });
}

function init(rawModelsData, rawPolicyModelsData) {
  tweakPoliciesCountries(rawModelsData);
  tweakPoliciesCountryGroups(rawModelsData);
  models.init(rawModelsData);
  render(<Policies models={rawPolicyModelsData} />, document.getElementById('root'));
}

if (document.readyState !== 'loading') {
  load();
} else {
  document.addEventListener('DOMContentLoaded', load);
}
