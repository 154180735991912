import React, { useRef, useState } from 'react';

import Icon from '../icon/icon';

import './styles.scss';

const IFRAME_PROPORTIONS = 0.5625;
const IFRAME_WIDTH = 640;
const IFRAME_HEIGHT = 640 * IFRAME_PROPORTIONS;

export default function ShareFields({ url, embedURL }) {
  return (
    <div className="share-fields">
      <ShareField name="copy" value={url} label="Copy link" />
      <ShareField
        name="embed"
        value={`<iframe src="${embedURL}" width="${IFRAME_WIDTH}" height="${IFRAME_HEIGHT}" frameborder="0" scrolling="no"></iframe>`}
        label="Embed map"
      />
    </div>
  );
}

function ShareField({ label, name, value }) {
  const inputEl = useRef(null);
  const [copied, setCopied] = useState(false);
  const handleInputFocus = (event) => {
    event.target.select();
  };
  // FIXME: Clear timeout on unmount (otherwise this may cause a memory leak)
  const handleClick = (event) => {
    inputEl.current.select();
    document.execCommand('copy');
    setCopied(true);
    inputEl.current.focus();
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className={`share-fields__field ${copied ? 'share-fields__field--copied' : null}`}>
      <label for={`share-fields-${name}`}>{label}</label>
      <div className="share-fields__input">
        <button onClick={handleClick} tabIndex="-1">
          <span>Copy</span>
          <Icon id="copy" />
        </button>
        <input
          onClick={handleClick}
          ref={inputEl}
          type="text"
          id={`share-fields-${name}`}
          value={value}
          onFocus={handleInputFocus}
          readonly="readonly"
        />
        {copied && <span>Link copied to clipboard</span>}
      </div>
    </div>
  );
}
