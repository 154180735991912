import React from 'react';

import DisclosurePanel from '../DisclosurePanel';

import './styles.scss';

// FIXME: Styles clash with disclosure panel styles
export default function NestedList({ data, renderItem, depth = 1 }) {
  return (
    <ul className="nested-list">
      {data.map((datum) => {
        return (
          <li className="nested-list__item">
            {datum.children ? (
              <DisclosurePanel icon_id="accordion" type="accordion">
                <span className="nested-list__item__header">{renderItem(datum, depth)}</span>
                <div className="nested-list__item__list">
                  <NestedList data={datum.children} renderItem={renderItem} depth={depth + 1} />
                </div>
              </DisclosurePanel>
            ) : (
              renderItem(datum, depth)
            )}
          </li>
        );
      })}
    </ul>
  );
}
