export function compareTrades(a, b) {
    if (!a || !b) {
        return false;
    }

    // XXX: Very loose equality only comparing exporter and importer
    return (
        a.exporter &&
        b.exporter &&
        a.exporter.model === b.exporter.model &&
        a.importer &&
        b.importer &&
        a.importer.model === b.importer.model
    );
}

export function ref(parent, name) {
    return function (node) {
        parent[name] = node && node.base ? node.base : node;
    };
}

export function nice(value) {
    if (!value) {
        return value;
    }

    value = value * 1000;

    let divisor = 1;
    if (value >= 1e12) {
        divisor = 1e12;
    } else if (value >= 1e9) {
        divisor = 1e9;
    } else if (value >= 1e6) {
        divisor = 1e6;
    } else if (value >= 1e3) {
        divisor = 1e3;
    }

    value = value / divisor;

    let unit = 0.5;
    if (value >= 20 - 4) {
        unit = 20;
    } else if (value >= 4 - 0.5) {
        unit = 4;
    } else if (value >= 2) {
        unit = 2;
    }

    let rounded = (Math.round(value / unit) * unit * divisor) / 1000;

    return rounded;
}

export function bemName(base, modifier) {
    if (!modifier) {
        return base;
    }

    return `${base} ${base}--${modifier}`;
}

export function fetch(url, callback) {
    const request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            callback(JSON.parse(request.responseText));
        }
    };
    request.send();
}
