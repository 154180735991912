import React from 'react';

import { PolicyMarkerIcon } from '../PolicyMarker';

import './styles.scss';

export default function PolicyMarkerLegend({ policyType }) {
  if (!policyType) {
    return null;
  }
  const childPolicyMarkerColours = (policyType.children || []).reduce((acc, child) => {
    if (child.model.icon?.colour && !acc.includes(child.model.icon.colour)) {
      acc.push(child.model.icon.colour);
    }
    return acc;
  }, []);
  const displayChildPolicyMarkers = childPolicyMarkerColours.length > 1;
  const markerColour =
    childPolicyMarkerColours.length === 1 ? childPolicyMarkerColours[0] : policyType.model.icon?.colour;

  return (
    <div class="policy-marker-legend" aria-hidden="true">
      <ul class="policy-marker-legend__list">
        {displayChildPolicyMarkers ? (
          <>
            {policyType.children.map((child) => {
              return (
                <li>
                  <PolicyMarkerIcon colour={child.model.icon?.colour} />
                  <span>{child.model.name}</span>
                </li>
              );
            })}
            <li>
              <PolicyMarkerIcon colour="#AAAAAA" highlighted hollow />
              <span>{__('policies-map-legend')}</span>
            </li>
          </>
        ) : (
          <li>
            <PolicyMarkerIcon colour={markerColour} highlighted />
            <span>{__('policies-map-legend')}</span>
          </li>
        )}
      </ul>
    </div>
  );
}
