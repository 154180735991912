import React from 'react';
import clsx from 'clsx';

import './styles.scss';

export function MapWrapper({ modifier, children }) {
  return (
    <div className={clsx('map-wrapper', modifier && `map-wrapper--${modifier}`)}>{children}</div>
  );
}

export function MapWrapperMain({ modifier, children }) {
  return (
    <div className="map-wrapper-main">
      <div>{children}</div>
    </div>
  );
}

export function MapWrapperHeader({ modifier, children }) {
  return (
    <div className={clsx('map-wrapper-header', modifier && `map-wrapper-header--${modifier}`)}>
      {children}
    </div>
  );
}

export function MapWrapperFooter({ modifier, children }) {
  return (
    <div className={clsx('map-wrapper-footer', modifier && `map-wrapper-footer--${modifier}`)}>
      {children}
    </div>
  );
}
