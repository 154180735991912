import React from 'react';
import clsx from 'clsx';

import './styles.scss';

const BOX_PADDING_X = 2;
const BOX_PADDING_Y = 1;

export default function MapLabel({
  text,
  yOffset = 0,
  textAnchor = 'middle',
  box,
  textRect,
  hovered,
  onHover,
  onClick,
}) {
  const boxRect = {
    width: textRect.width + BOX_PADDING_X * 2,
    height: textRect.height + BOX_PADDING_Y * 2,
  };

  return (
    <g
      className={clsx('map-label', onClick && 'map-label--interactive', hovered && 'map-label--hovered')}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      onClick={onClick}
    >
      {box ? (
        <rect className="map-label__box" x={boxRect.width / -2} y={1 - BOX_PADDING_Y} width={boxRect.width} height={boxRect.height} />
      ) : null}
      <text className="map-label__text" y={yOffset} style={{ textAnchor: textAnchor }}>
        <tspan>{text}</tspan>
      </text>
    </g>
  );
}
