import React, { Component } from 'react';
import { geoStream, geoClipAntimeridian, geoPath } from 'd3-geo';
import { geoNaturalEarth as geoProjection } from 'd3-geo-projection';
import { feature as topojsonFeature, mergeArcs as topojsonMergeArcs } from 'topojson';
import partition from 'lodash/partition';

import MapCountries from '../map-countries/map-countries';
import MapMarkersLayer from '../MapMarkersLayer';
import MapBackground from '../MapBackground/svg.js';
import MapMarkerLabelsLayer from '../MapMarkerLabelsLayer';
import ZoomRegionsDebugLayer from '../ZoomRegionsDebugLayer';

import { DimensionsContext } from '../dimensions/dimensions.jsx';

import { matchBreakpoint } from '../../utilities/breakpoints.js';
import { getBoundsGeoPolygon, getZoomTransformFromBounds } from '../../utilities/geometry.js';

import { TEST_REGION_BOUNDS, POLICIES_EU27_COUNTRYGROUP_COUNTRY_IDS } from '../../constants.js';

import './styles.scss';

// Test loading data from world-atlas NPM package
const world = require('../../assets/world-110m.json');
// const world = require('world-atlas/countries-110m.json');

console.log(world);
let countriesFeature;
function getCountriesFeature() {
  if (countriesFeature) {
    return countriesFeature;
  }

  // FIXME: This probably mutates the data, although this may not matter
  const countries = world.objects.countries;

  countries.geometries = countries.geometries.filter(function (geometry) {
    return geometry.id !== 'AQ' && geometry.id.length >= 2;
  });

  // Merge EU-27 countries into single boundary
  /*
  const [euGeometries, otherGeometries] = partition(countries.geometries, function (geometry) {
    return POLICIES_EU27_COUNTRYGROUP_COUNTRY_IDS.includes(geometry.id);
  });

  const euGeometry = topojsonMergeArcs(world, euGeometries);

  countries.geometries = [...otherGeometries, euGeometry];
  */

  countriesFeature = topojsonFeature(world, countries);
  return countriesFeature;
}

// const EU27_RECT = [
//   [-54.52434524345243, 2.0536768523685254],
//   [34.00414004140043, 70.16492375603755],
// ];

/*
const REGION_BOUNDS = {
  Africa: [
    [-25.342453424534227, -49.709831844568434],
    [70.55530555305555, 37.34032534200344],
  ],
  Americas: [
    [172.49392493924938, -58.49227331726442],
    [-11.424714247142475, 83.59960937500003],
  ],
  Asia: [
    [25.670056700567017, -10.91015465248401],
    [145.8320583205832, 55.389553251001274],
  ],
  Europe: [
    [-68.370083700837, -21.36957546153586],
    [-169.7290972909729, 81.85492590394657],
  ],
  // Oceania: [
  //   [-180, -53.18531075904508],
  //   [180, 18.80675246939971],
  // ],
  'Latin America and the Caribbean': [
    [-118.39978399783998, -58.49227331726442],
    [-26.260462604626042, 32.715612141121426],
  ],
  'Eastern Europe': [
    [12.09072090720909, 41.19946101976646],
    [-169.7290972909729, 81.85492590394657],
  ],
  'Northern Europe': [
    [-24.47484474844748, 49.16945287609127],
    [33.62973629736297, 80.47827516509543],
  ],
  'Southern Europe': [
    [-31.282512825128236, 27.64648205607058],
    [28.233282332823336, 47.08277672464226],
  ],
  'Western Europe': [
    [-68.370083700837, -21.36957546153586],
    [55.83835838358385, 55.05797659132844],
  ],
  'Australia and New Zealand': [
    [73.2517325173252, -53.18531075904508],
    [-171.1871118711187, -8.54571794842947],
  ],
  Melanesia: [
    [-180, -22.661162031151548],
    [180, -1.3534556394001243],
  ],
  Micronesia: [
    [131.13671136711366, -11.456995740426137],
    [-151.7829178291783, 18.80675246939971],
  ],
  Polynesia: [
    [-178.19278192781928, -24.412789516020155],
    [-128.28908289082892, -8.781814418144165],
  ],
};
*/

// Refactor to reduce duplication from Map component
export default class MarkersMap extends Component {
  static contextType = DimensionsContext;

  constructor(props, context) {
    super();

    /*
    // FIXME: This probably mutates the data, although this may not matter
    const countries = world.objects.countries;

    countries.geometries = countries.geometries.filter(function (geometry) {
      return geometry.id !== 'AQ' && geometry.id.length >= 2;
    });

    const [euGeometries, otherGeometries] = partition(countries.geometries, function (geometry) {
      return EU27_IDS.includes(geometry.id);
    });

    const euGeometry = topojsonMergeArcs(world, euGeometries);

    countries.geometries = [...otherGeometries, euGeometry];

    this.countries = topojsonFeature(world, countries);
    */

    this.countries = getCountriesFeature();

    // Skip Antarctica
    // FIXME: Remove from data so we don't load it?
    // This breaks the projection bounds
    // this.countries.features = this.countries.features.filter(function (feature) {
    //   return feature.id !== 'AQ' || feature.id.length > 2;
    // });

    this.projection = this.getProjection(context.width, context.height);
    this.originalProjectionScale = this.projection.scale();
    this.originalProjectionTranslate = this.projection.translate();

    console.log('projection', this.projection.scale(), this.projection.translate());

    this.state = {
      transform: null,
      debug: false,
    };
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.context.width !== nextContext.width || this.context.height !== nextContext.height) {
      this.projection = this.getProjection(nextContext.width, nextContext.height);
      this.originalProjectionScale = this.projection.scale();
      this.originalProjectionTranslate = this.projection.translate();
    }
  }

  getProjection(width, height) {
    return geoProjection().fitSize([width, height], this.countries).rotate([-11.5, 0, 0]);
  }

  // TODO: Need to defer rendering defs, flows and hitboxes until labels
  // have been laid out?
  render(props, state, context) {
    const enableInteraction = matchBreakpoint('tablet').matches;
    const showLabels = enableInteraction;
    let width = context.trueWidth;
    let height = context.trueHeight;
    let viewboxWidth = context.width;
    let viewboxHeight = context.height;

    if (props.width && props.height) {
      width = viewboxWidth = props.width;
      height = viewboxHeight = props.height;
      this.projection = this.getProjection(width, height);
    }

    let transformObj = { transform: null, transformRaw: null };
    if (props.zoomCountries) {
      transformObj = this.getCountryPointBoundsZoomTransform(props.zoomCountries);
      console.log('transformObj', transformObj);
    }

    return (
      <div className="markers-map" style={{ width: `${width}px`, height: `${height}px` }}>
        {/* <MapBackground /> */}
        <svg className="markers-map__svg" viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}>
          <g transform={transformObj.transform}>
            <g>
              <MapBackground x={0} y={0} width={viewboxWidth} height={viewboxHeight} />
            </g>
            <g>
              <MapCountries countries={this.countries.features} projection={this.projection} />
            </g>
            {state.debug && (
              <ZoomRegionsDebugLayer
                regionBounds={TEST_REGION_BOUNDS}
                projection={this.projection}
              />
            )}
          </g>
          <MapMarkersLayer
            data={props.data}
            projection={this.projection}
            transform={transformObj.transformRaw}
            onHoverMarker={enableInteraction ? props.onHoverCountry : null}
            onClickMarker={props.onClickCountry && enableInteraction ? props.onClickCountry : null}
          />
          {showLabels ? (
            <MapMarkerLabelsLayer
              projection={this.projection}
              transform={transformObj.transformRaw}
              countries={props.countries}
              hoveredCountries={props.hoveredCountries}
              onHover={props.onHoverCountry}
              onClick={props.onClickCountry ? props.onClickCountry : null}
            />
          ) : null}
        </svg>
        {/* <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 4,
            textAlign: 'right',
          }}
        >
          <div>
            <select onChange={this.handleChangeRegion}>
              <option value="" selected>
                World
              </option>
              {Object.keys(TEST_REGION_BOUNDS).map((key) => {
                return <option value={key}>{TEST_REGION_NAMES[key]}</option>;
              })}
            </select>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={state.debug}
                value="1"
                onChange={this.handleChangeDebug}
              />
              Debug
            </label>
          </div>
        </div> */}
      </div>
    );
  }

  handleChangeRegion = (event) => {
    console.log(event.currentTarget.value);
    this.setTransformToRegion(event.currentTarget.value);
  };

  handleChangeDebug = (event) => {
    this.setState({
      debug: event.target.checked,
    });
  };

  getCountryPointBoundsZoomTransform(countries, context) {
    if (!countries || !countries.length) {
      return { transform: null, transformRaw: null };
    }

    context = context || this.context;

    console.log('get zoom transform', countries);

    const coordinates = countries.map((country) => {
      return [country.model.lng, country.model.lat];
    });
    const geojson = { type: 'MultiPoint', coordinates: coordinates };

    const path = geoPath().projection(this.projection);
    const pathBounds = path.bounds(geojson);

    return getZoomTransformFromBounds(pathBounds, context, 0.2);
  }
}
