import React, { Component } from 'react';
import clsx from 'clsx';

import { TabsContext } from '../../components/tabs/tabs';

import './_tab.scss';

export default class Tab extends Component {
  static contextType = TabsContext;

  constructor() {
    super();

    // Preact 4.x doesn't autobind event handlers
    this._handleClick = this.handleClick.bind(this);
  }

  render(props, state, context) {
    if (this.index === undefined) {
      this.index = context.registerTab();
    }

    let active = context.isActive(this.index);

    return (
      <UnconnectedTab
        kind={props.kind}
        modifier={props.modifier}
        disabled={props.disabled}
        index={this.index}
        active={active}
        onClick={this._handleClick}
      >
        {props.children}
      </UnconnectedTab>
    );
  }

  handleClick(event) {
    event.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.context.activate(this.index);
  }
}

export function UnconnectedTab(props) {
  return (
    <li
      class={clsx(
        'tab',
        props.kind && `tab--${props.kind}`,
        props.modifier && `tab--${props.modifier}`
      )}
      role="tab"
      aria-disabled={props.disabled}
      aria-controls={'tabpanel' + props.index}
      aria-selected={props.active}
      tabindex={props.active ? 0 : -1}
      onClick={props.onClick}
    >
      {props.children}
    </li>
  );
}
