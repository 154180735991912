import React from 'react';
import collide from 'box-collide';

import MapLabel from '../MapLabel';

import { memoizedMeasureTextString as measureTextString } from '../../utilities/measureText.js';
import { transformPoint } from '../../utilities/geometry.js';

const FONT_SIZE = 14;
const DEFAULT_PADDING = 4;
const POSITIONS = ['t', 'b', 'tl', 'tr', 'bl', 'br'];
const MARKER_RADIUS = 8;
const DEBUG = false;

function calculateRect(point, textRect, position) {
  switch (position) {
    case 't':
      return {
        x: point[0] - textRect.hwidth,
        y: point[1] - MARKER_RADIUS - textRect.height,
        width: textRect.width,
        height: textRect.height,
      };
    case 'tl':
      return {
        x: point[0] - textRect.width - MARKER_RADIUS,
        y: point[1] - MARKER_RADIUS - textRect.height + MARKER_RADIUS,
        width: textRect.width,
        height: textRect.height,
      };
    case 'tr':
      return {
        x: point[0] + MARKER_RADIUS,
        y: point[1] - MARKER_RADIUS - textRect.height + MARKER_RADIUS,
        width: textRect.width,
        height: textRect.height,
      };
    case 'b':
      return {
        x: point[0] - textRect.hwidth,
        y: point[1] + MARKER_RADIUS,
        width: textRect.width,
        height: textRect.height,
      };
    case 'bl':
      return {
        x: point[0] - textRect.width - MARKER_RADIUS,
        y: point[1] + MARKER_RADIUS - MARKER_RADIUS,
        width: textRect.width,
        height: textRect.height,
      };
    case 'br':
      return {
        x: point[0] + MARKER_RADIUS,
        y: point[1] + MARKER_RADIUS - MARKER_RADIUS,
        width: textRect.width,
        height: textRect.height,
      };
  }
}

function findPosition(point, textRect, occupiedRects, ignoreOccupiedRectId) {
  for (let i = 0; i < POSITIONS.length; i++) {
    const position = POSITIONS[i];
    const rect = calculateRect(point, textRect, position);

    let placed = true;

    for (let j = 0; j < occupiedRects.length; j++) {
      if (
        occupiedRects[j].id &&
        ignoreOccupiedRectId &&
        ignoreOccupiedRectId === occupiedRects[j].id
      ) {
        continue;
      }

      if (collide(rect, occupiedRects[j])) {
        placed = false;
        break;
      }
    }

    if (placed) {
      return {
        position: position,
        textX: position[1]
          ? position[1] === 'l'
            ? point[0] - MARKER_RADIUS
            : point[0] + MARKER_RADIUS
          : point[0],
        textY: rect.y,
        textAnchor: position[1] ? (position[1] === 'l' ? 'end' : 'start') : 'middle',
        rect: rect,
      };
    }
  }

  return null;
}

// TODO: Refactor to store occupied rects using a 'bitmap' grid representation of canvas?
// https://idl.cs.washington.edu/papers/fast-labels/
export default function MapMarkerLabelsLayer({
  projection,
  transform,
  countries,
  hoveredCountries,
  onHover,
  onClick,
}) {
  let occupiedRects = [];

  // Prepopulate occupied rects with marker rects
  // FIXME: Projecting country points multiple times
  occupiedRects = countries.map((country) => {
    const point = transformPoint(projection([country.model.lng, country.model.lat]), transform);
    return {
      id: country.model.id,
      x: point[0] - MARKER_RADIUS,
      y: point[1] - MARKER_RADIUS,
      width: MARKER_RADIUS * 2,
      height: MARKER_RADIUS * 2,
    };
  });

  const handleHover = (id) => (event) => {
    if (event.type === 'mouseenter') {
      onHover(id);
    } else {
      onHover(null);
    }
  };

  const handleClick = (id) => () => {
    onClick(id);
  };

  return (
    <g className="map-markers-labels-layer">
      {countries.map((country) => {
        if (!country) {
          return null;
        }

        // Use map name only if its one a single ine
        let label =
          country.model.map_name && country.model.map_name.indexOf('\\n') === -1
            ? country.model.map_name
            : country.model.name;

        const point = transformPoint(projection([country.model.lng, country.model.lat]), transform);
        const textRect = measureTextString(label, FONT_SIZE, DEFAULT_PADDING);

        let position = findPosition(point, textRect, occupiedRects, country.model.id);

        if (
          !position &&
          country.model.map_short_name &&
          country.model.map_short_name.toUpperCase() === country.model.map_short_name
        ) {
          const shortTextRect = measureTextString(
            country.model.map_short_name,
            FONT_SIZE,
            DEFAULT_PADDING
          );
          position = findPosition(point, shortTextRect, occupiedRects, country.model.id);
          if (position) {
            label = country.model.map_short_name;
          }
        }

        if (position) {
          occupiedRects.push(position.rect);
          return (
            <g
              id={`label-${country.model.id}`}
              transform={`translate(${position.textX} ${position.textY})`}
            >
              <MapLabel
                text={label}
                yOffset={FONT_SIZE}
                textAnchor={position.textAnchor}
                box={country.model.box}
                textRect={textRect}
                hovered={hoveredCountries.length && !hoveredCountries.includes(country)}
                onHover={onHover && handleHover(country.model.id)}
                onClick={onClick && handleClick(country.model.id)}
              />
            </g>
          );
        }

        return null;
      })}
      {DEBUG &&
        occupiedRects.map((rect) => {
          return (
            <rect
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              style={{ stroke: 'aqua', fill: 'none', opacity: 0.3 }}
            />
          );
        })}
    </g>
  );
}
