import React, { Component } from 'react';
import { geoPath } from 'd3-geo';

import './_map-countries.scss';

export default class MapCountries extends Component {
    // shouldComponentUpdate (nextProps) {
    //     return (nextProps.projection !== this.props.projection);
    // }

    render (props) {
        let pathGenerator = geoPath().projection(props.projection);

        return (
            <g className="map__countries">
                { props.countries.map(function (country) {
                    return <path d={ pathGenerator(country.geometry) } />;
                }) }
            </g>
        )
    }
}
