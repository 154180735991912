import queryString from 'qs';

export function createYearRangeModels(startYear, endYear, singleYearThreshold) {
  const models = [];
  let decade = Math.floor(startYear / 10) * 10;
  while (decade < singleYearThreshold) {
    models.push({ id: `${decade}s`, name: `${decade}-${decade + 9}`, range: [0, decade + 9] });
    decade += 10;
  }
  let year = singleYearThreshold;
  while (year <= endYear) {
    models.push({ id: year, name: year });
    year += 1;
  }
  console.log(models);
  return models.reverse();
}

export function stateToQuerystring(state) {
  const params = [];

  if (state.activeType && state.activeType.id) {
    params.push(`policy=${state.activeType.id}`);
  }

  if (state.activeYear && state.activeYear.id) {
    params.push(`year=${state.activeYear.id}`);
  }

  if (state.activeRegion && state.activeRegion.id) {
    params.push(`regionzoom=${state.activeRegion.id}`);
  }

  if (state.preview) {
    params.push(`preview=1`);
  }

  return params.join('&');
}

export function querystringToState(store, yearsCollection, regionCollection) {
  const params = queryString.parse(window.location.search.replace(/^\?/, ''));
  const state = {};

  state.activeYear = params.year ? yearsCollection.get(params.year) : yearsCollection.getDefault();
  state.activeType = params.policy
    ? store.types.data.get(params.policy)
    : store.types.data.getDefault();
  state.activeRegion = params.regionzoom
    ? regionCollection.get(params.regionzoom)
    : regionCollection.getDefault();
  state.preview = !!params.preview;

  return state;
}
