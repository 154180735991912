import React from 'react';
import clsx from 'clsx';

import Icon from '../icon/icon';

import './_icon-button.scss';

export default function IconButton(props) {
  const hasLabel = props.children && props.children.length;
  const classNames = clsx(
    'iconbutton',
    hasLabel && 'iconbutton--labelled',
    props.icon === 'loader' && 'iconbutton--loader',
    props.kind && `iconbutton--${props.kind}`,
    props.size && `iconbutton--${props.size}`
  );

  if (props.href) {
    return (
      <a class={classNames} href={props.href} download={props.download || null}>
        <Icon id={props.icon} />
        {hasLabel && <span>{props.children}</span>}
      </a>
    );
  }

  return (
    <button
      type="button"
      class={classNames}
      disabled={props.disabled}
      aria-expanded={props.ariaExpanded}
      aria-label={props.ariaLabel}
      onClick={props.handleClick}
    >
      {props.icon === 'loader' ? (
        <i className="icon iconbutton__loader" />
      ) : (
        <Icon id={props.icon} />
      )}
      {hasLabel && <span>{props.children}</span>}
    </button>
  );
}
