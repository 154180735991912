import React from 'react';
import sortBy from 'lodash/sortBy';

import {
  DashboardColumns,
  DashboardColumnsFooter,
  DashboardColumnHeader,
  DashboardColumnBody,
} from '../../components/DashboardColumns';
import ListBars from '../../components/list-bars/list-bars.jsx';

import { formatWithSuffixFactory } from '../../utilities/formatters.js';
import formatters from '../../formatters.js';

import data from '../../data/waste-electronic.json';

const COLUMNS = [
  {
    metric: 'Total eWaste Generated',
    title: __('waste-electronic-a-title'),
    subtitle: __('waste-electronic-a-subtitle'),
    formatter: (value) => {
      return `${formatters.thousands(value)} ${__('waste-electronic-a-unit')}`;
    },
  },
  {
    metric: 'eWaste Generated Per Capita',
    title: __('waste-electronic-b-title'),
    subtitle: __('waste-electronic-b-subtitle'),
    formatter: formatWithSuffixFactory(__('waste-electronic-b-unit'), 0),
  },
  {
    metric: 'eWaste Growth Per Capita',
    title: __('waste-electronic-c-title'),
    subtitle: __('waste-electronic-c-subtitle'),
    formatter: formatters.percentage,
  },
  {
    metric: 'eWaste Collected and Recycled',
    title: __('waste-electronic-d-title'),
    subtitle: __('waste-electronic-d-subtitle'),
    formatter: formatters.percentage,
  },
];

export default function ElectronicWasteDashboard() {
  return (
    <DashboardColumns columns={4}>
      {COLUMNS.map((column) => {
        const metricData = data.map((datum) => ({
          label: datum.Region,
          value: datum[column.metric],
          formattedValue: column.formatter
            ? column.formatFullContext
              ? column.formatter(datum)
              : column.formatter(datum[column.metric])
            : datum[column.metric],
          disabled: true,
        }));
        const sortedMetricData = sortBy(metricData, 'value').reverse();
        return (
          <>
            <DashboardColumnHeader>
              <h1>{column.title}</h1>
              <h2>{column.subtitle}</h2>
            </DashboardColumnHeader>
            <DashboardColumnBody>
              <ListBars data={sortedMetricData} />
            </DashboardColumnBody>
          </>
        );
      })}
      <DashboardColumnsFooter>
        {__config.routes && __config.routes.about ? (
          <p>
            <a href={__config.routes.about}>{__('waste-sources')}</a>
          </p>
        ) : null}
        <p>{__('waste-footnote')}</p>
      </DashboardColumnsFooter>
    </DashboardColumns>
  );
}
