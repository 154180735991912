import React from 'react';
import clsx from 'clsx';

import './styles.scss';

export function Filters({ layout, children }) {
  return <div className={clsx('filters', layout && `filters--${layout}`)}>{children}</div>;
}

export function FiltersItem({ children }) {
  return <div className="filters-item">{children}</div>;
}
