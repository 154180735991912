import React from 'react';

import Icon, { CircleIcon } from '../icon/icon';

export default function PolicyIcon({ options, highlighted }) {
  return options && options.id ? (
    <Icon id={options.id} colour={options?.colour} highlighted={highlighted} />
  ) : (
    <CircleIcon colour={options?.colour} highlighted={highlighted} />
  );
}
