import React from 'react';
import { hex as hexContrast } from 'wcag-contrast';

import './styles.scss';

const COLOUR_WHITE = '#FFFFFF';
const COLOUR_BLACK = '#1A1A1A';

export default function PolicyLabel({ policyType }) {
  const bgColour = policyType.model.icon.colour;
  const contrast = hexContrast(bgColour, COLOUR_BLACK);
  const fgColour = contrast > 8 ? COLOUR_BLACK : COLOUR_WHITE;

  return (
    <div className="policy-label">
      <div className="policy-label__inner" style={{ backgroundColor: bgColour, color: fgColour }}>
        {policyType.model.name}
      </div>
    </div>
  );
}
