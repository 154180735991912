export function transformPoint(point, transform) {
  if (!transform) {
    return point;
  }

  return [
    point[0] * transform.scale + transform.translate[0],
    point[1] * transform.scale + transform.translate[1],
  ];
}

export function scalePoint(point, transform) {
  if (!transform) {
    return point;
  }

  return [point[0] * transform.scale, point[1] * transform.scale];
}

export function getBoundsGeoPolygon(bounds) {
  return {
    type: 'Polygon',
    coordinates: [
      [
        [bounds[0][0], bounds[1][1]],
        [bounds[1][0], bounds[1][1]],
        [bounds[1][0], bounds[0][1]],
        [bounds[0][0], bounds[0][1]],
        [bounds[0][0], bounds[1][1]],
      ],
    ],
  };
}

export function getZoomTransformFromBounds(bounds, context, paddingPc = 0.125, maxScale = 3.25) {
  const width = context.width;
  const height = context.height;

  // https://bl.ocks.org/piwodlaiwo/90777c94b0cd9b6543d9dfb8b5aefeef
  // https://observablehq.com/@d3/zoom-to-bounding-box
  // const [[x0, y0], [x1, y1]] = path.bounds(d);
  // d3.zoomIdentity
  //     .translate(width / 2, height / 2)
  //     .scale(Math.min(8, 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height)))
  //     .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),

  const dx = bounds[1][0] - bounds[0][0];
  const dy = bounds[1][1] - bounds[0][1];
  const x = (bounds[0][0] + bounds[1][0]) / 2;
  const y = (bounds[0][1] + bounds[1][1]) / 2;
  // const scale = Math.max(1, Math.min(maxScale, 0.9 / Math.max(dx / width, dy / height)));
  const scale = Math.max(
    1,
    Math.min(maxScale, (1 - paddingPc) / Math.max(dx / width, dy / height))
  );
  let translate = [width / 2 - scale * x, height / 2 - scale * y];
  // const unscaledTranslate = [width / 2 - x, height / 2 - y];
  // const translation = [-x, -y];

  console.log('zoom', scale, maxScale, translate);

  // Only apply horizontal translation if scale is 100%
  if (scale === 1) {
    //   translate = [0, 0];
    translate[1] = 0;
  }

  return {
    transform: `translate(${translate}) scale(${scale})`,
    transformRaw: {
      translate,
      scale,
    },
  };
}

export function getCoordinatesBounds(coordinates) {
  const bounds = [
    [Infinity, Infinity],
    [0, 0],
  ];

  coordinates.forEach((coord) => {
    bounds[0][0] = Math.min(coord[0], bounds[0][0]);
    bounds[1][0] = Math.max(coord[0], bounds[1][0]);
    bounds[0][1] = Math.min(coord[1], bounds[0][1]);
    bounds[1][1] = Math.max(coord[1], bounds[1][1]);
  });

  return bounds;
}
