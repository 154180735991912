import React from 'react';

import NestedList from '../NestedList';
import { PolicyMarkerIcon } from '../PolicyMarker';

import './styles.scss';

export default function CountriesList({ data, renderItem }) {
  return (
    <div className="countries-list">
      <div className="countries-list__header" aria-hidden="true">
        <p>{__('policies-list-country')}</p>
        <p>{__('policies-list-total')}</p>
      </div>
      <div className="countries-list__list">
        <NestedList data={data} renderItem={renderItem} />
      </div>
    </div>
  );
}

export function CountriesListItem({ title, count, colour, highlighted, onClick }) {
  return (
    <button className="countries-list-item" type="button" onClick={onClick}>
      <PolicyMarkerIcon colour={colour} highlighted={highlighted} />
      <span className="countries-list-item__title">{title}</span>
      {count && <span className="countries-list-item__count">{count}</span>}
    </button>
  );
}
