import React from 'react';
import clsx from 'clsx';

import './_tablist.scss';

export default function Tablist(props) {
  return (
    <div
      class={clsx(
        'tablist',
        props.kind && `tablist--${props.kind}`
      )}
    >
      <div className="tablist__viewport">
        <ul className="tablist__list" role="tablist">
          {props.children}
        </ul>
      </div>
    </div>
  );
}
