import React from 'react';

import PolicyIcon from '../PolicyIcon';
import StatusFlag from '../StatusFlag';

import './styles.scss';

export default function PoliciesReadout({ country, policiesData, policiesTotal }) {
  return (
    <div className="policies-readout">
      <div className="policies-readout__header">
        <h1>{country.model.name}</h1>
        {policiesTotal && (
          <p>
            {policiesTotal} {policiesTotal > 1 ? 'policies' : 'policy'}
          </p>
        )}
      </div>
      <div className="policies-readout__body">
        <ul>
          {policiesData.map((datum) => {
            return (
              <li className="policies-readout__policy">
                <span className="policies-readout__policy__type">
                  <PolicyIcon
                    options={datum.type.model.icon}
                    highlighted={datum.status}
                  />
                  {datum.type.model.name}
                  {datum.status && <StatusFlag status={datum.status} />}
                </span>
                <span>({datum.count})</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
