import React from 'react';

import IconButton from '../icon-button/icon-button.jsx';

// XXX: Duplicated in Craft project component behaviour
export const WELCOME_MODAL_TRIGGER_EVENT_NAME = 'show-welcome-modal';

export default function WelcomeModalButton() {
  const handleClick = function () {
    const event = new Event(WELCOME_MODAL_TRIGGER_EVENT_NAME);
    window.dispatchEvent(event);
  };

  return <IconButton icon="info" kind="rounded" handleClick={handleClick} />;
}
