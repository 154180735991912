export function formatWithSuffix(value, suffix, precision) {
  return `${precision !== undefined ? value.toFixed(precision) : value} ${suffix}`;
}

export function formatWithSuffixFactory(suffix, precision) {
  return function (value) {
    return formatWithSuffix(value, suffix, precision);
  };
}

export function formatFriendlyUrl(url) {
  return url.replace(/https?:\/\/(www\.)?/, '');
}

export function formatYearSpan(start, end) {
  if (!end) {
    return start;
  }

  const startStr = start.toString();
  const endStr = end.toString();

  if (startStr.slice(0, 2) === endStr.slice(0, 2)) {
    return `${startStr}–${endStr.slice(2, 4)}`;
  } else {
    return `${startStr}–${endStr}`;
  }
}
