import React from 'react';

import PolicyLabel from '../PolicyLabel';

import './styles.scss';

export default function PoliciesDescriptionsDashboard({ type }) {
  return (
    <div className="policies-descriptions-dashboard">
      <h2 className="policies-descriptions-dashboard__heading">Progress of policies</h2>
      <ol className="policies-descriptions-dashboard__inner">
        {type.children.map((child) => (
          <li className="policies-descriptions-dashboard__item">
            <PolicyLabel policyType={child} />
            <p className="policies-descriptions-dashboard__item__description">
              {child.model.description}
            </p>
          </li>
        ))}
      </ol>
    </div>
  );
}
