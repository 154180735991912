import React from 'react';

import PolicyMarker from '../PolicyMarker';

import { transformPoint } from '../../utilities/geometry.js';

import './styles.scss';

export default function MapMarkersLayer({
  data,
  projection,
  transform,
  onHoverMarker,
  onClickMarker,
}) {
  if (!data) {
    return null;
  }

  const handleHoverMarker = (id) => (event) => {
    if (event.type === 'mouseenter') {
      onHoverMarker(id);
    } else {
      onHoverMarker(null);
    }
  };

  const handleClickMaker = (id) => () => {
    onClickMarker(id);
  };

  return (
    <g className="map-markers-layer">
      {data.map((datum) => {
        const country = datum.country;

        if (!country) {
          return null;
        }

        const point = transformPoint(projection([country.model.lng, country.model.lat]), transform);

        return !country.model.box ? (
          <PolicyMarker
            x={point[0]}
            y={point[1]}
            highlighted={datum.highlighted}
            colour={datum.colour}
            onHover={onHoverMarker && handleHoverMarker(country.id)}
            onClick={onClickMarker && handleClickMaker(country.id)}
          />
        ) : null;
      })}
    </g>
  );
}
