import React from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';

import PoliciesList from '../PoliciesList';
import Icon from '../icon/icon';
import Loader from '../loader/loader.jsx';

import './styles.scss';

export default function PoliciesOverlay({
  transition = false,
  country,
  policies,
  totalPolicies,
  onClickClose,
}) {
  return (
    <div className={clsx('policies-overlay', transition && 'policies-overlay--transition')}>
      {/*
        Prevent Google Translate content duplication by adding unique key to header to ensure it
        is always rerendered
      */}
      <div key={country.model.id} className="policies-overlay__header">
        <button type="button" onClick={onClickClose && onClickClose}>
          <Icon id="close" />
        </button>
        <div>
          <h1>{country.model.name}</h1>
          <p>
            {totalPolicies ? `${totalPolicies} ${totalPolicies > 1 ? 'policies' : 'policy'}` : ''}
          </p>
        </div>
      </div>
      <div className="policies-overlay__body">
        {policies ? <PoliciesList policies={policies} /> : <Loader visible />}
      </div>
    </div>
  );
}

export function TransitionPoliciesOverlay({ active, ...props }) {
  // FIXME: On mobile the transition timeout will cause a delay before the overlay is unmounted
  return (
    <CSSTransition
      in={active}
      timeout={350}
      classNames="transition"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      <PoliciesOverlay transition={true} {...props} />
    </CSSTransition>
  );
}
