import FlatCollection from '../../shared/collection-flat.js';
import ParentCollection from '../../shared/collection-parent.js';
import TreeCollection from '../../shared/collection-tree.js';

const models = {
  init: function (rawData) {
    this.years = new FlatCollection(rawData.years);

    this.categories = new TreeCollection(rawData.categories);
    this.categories.setDefault({ id: null, name: 'All commodities' });

    this.countries = new FlatCollection(rawData.countries, true);
    this.countries.setDefault({ id: null, name: 'All countries' });

    this.categoryGroups = new ParentCollection(rawData.categoryGroups, this.categories);
    this.countryGroups = new ParentCollection(rawData.countryGroups, this.countries);

    this.countryGroupsMain = this.countryGroups.filter('type', 'group');
    this.countryGroupsRegions = this.countryGroups.filter('type', 'region');
  },
};

export default models;
