import React from 'react';
import sortBy from 'lodash/sortBy';

import {
  DashboardColumns,
  DashboardColumnFooter,
  DashboardColumnHeader,
  DashboardColumnBody,
} from '../../components/DashboardColumns';
import ListBars from '../../components/list-bars/list-bars.jsx';

import { formatWithSuffixFactory, formatWithSuffix } from '../../utilities/formatters.js';
import formatters from '../../formatters.js';

import data from '../../data/waste-solid.json';

const COLUMNS = [
  {
    metric: 'Waste Generated Per Capita',
    title: __('waste-solid-a-title'),
    subtitle: __('waste-solid-a-subtitle'),
    formatter: formatWithSuffixFactory(__('waste-solid-a-unit'), 0),
  },
  {
    metric: 'Waste Collected',
    title: __('waste-solid-b-title'),
    subtitle: __('waste-solid-b-subtitle'),
    formatter: formatters.percentage,
  },
  {
    metric: 'Waste Recycled or Composted',
    title: __('waste-solid-c-title'),
    subtitle: __('waste-solid-c-subtitle'),
    formatter: formatters.percentage,
  },
  {
    metric: 'Projected Waste Growth to 2050_1',
    title: __('waste-solid-d-title'),
    subtitle: __('waste-solid-d-subtitle'),
    formatter: function (datum) {
      const pc = datum['Projected Waste Growth to 2050'];
      const weight = datum['Projected Waste Growth to 2050_1'];
      return `${formatWithSuffix(weight, 'Mt')} (${formatters.percentage(pc)} growth)`;
    },
    formatFullContext: true,
  },
];

export default function SolidWasteDashboard() {
  return (
    <DashboardColumns columns={4}>
      {COLUMNS.map((column) => {
        const metricData = data.map((datum) => ({
          label: datum.Region,
          value: datum[column.metric],
          formattedValue: column.formatter
            ? column.formatFullContext
              ? column.formatter(datum)
              : column.formatter(datum[column.metric])
            : datum[column.metric],
          disabled: true,
        }));
        const sortedMetricData = sortBy(metricData, 'value').reverse();
        return (
          <>
            <DashboardColumnHeader>
              <h1>{column.title}</h1>
              <h2>{column.subtitle}</h2>
            </DashboardColumnHeader>
            <DashboardColumnBody>
              <ListBars data={sortedMetricData} />
            </DashboardColumnBody>
          </>
        );
      })}
      <DashboardColumnFooter>
        {__config.routes && __config.routes.about ? (
          <p>
            <a href={__config.routes.about}>{__('waste-sources')}</a>
          </p>
        ) : null}
      </DashboardColumnFooter>
      <DashboardColumnFooter>
        <p>{__('waste-footnote')}</p>
      </DashboardColumnFooter>
      <DashboardColumnFooter>
        <p>{__('policies-map-footnote')}</p>
      </DashboardColumnFooter>
    </DashboardColumns>
  );
}
