import React, { Component } from 'react';

import './_share-list.scss';

export default class ShareList extends Component {
  render(props) {
    const url = encodeURIComponent(props.url);

    // FIXME: Handle missing metadata more gracefully
    const metaTitle = document.querySelector('meta[property="og:title"]');
    const metaDescription = document.querySelector('meta[property="og:description"]');
    const metaTwitter = document.querySelector('meta[name="twitter:site"]');
    const title = encodeURIComponent(metaTitle ? metaTitle.content : '');
    const description = encodeURIComponent(metaDescription ? metaDescription.content : '');
    const twitterUsername = encodeURIComponent(
      metaTwitter ? metaTwitter.content.replace('@', '') : ''
    );

    const emailBody = `${description}${encodeURIComponent('\n')}${url}`;

    const twitterText = props.message ? encodeURIComponent(props.message) : title;

    const services = [
      {
        name: 'Twitter',
        url: `http://twitter.com/intent/tweet?url=${url}&text=${twitterText}&via=${twitterUsername}`,
      },
      {
        name: 'Facebook',
        url: `https://www.facebook.com/sharer.php?u=${url}`,
      },
      {
        name: 'LinkedIn',
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
      },
      {
        name: 'Email',
        url: `mailto:?subject=${title}&body=${emailBody}`,
        windowless: true,
      },
      {
        name: 'WhatsApp',
        url: `https://wa.me/?text=${url}`,
      },
    ];

    const className = ['sharelist'];
    if (props.kind) {
      className.push(`sharelist--${props.kind}`);
    }

    return (
      <ul class={className.join(' ')}>
        {services.map((service) => {
          return (
            <li className="sharelist__item">
              <a
                href={service.url}
                target={service.windowless ? null : '_blank'}
                rel="noopener noreferrer"
                onClick={this.handleClick}
              >
                {service.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  handleClick = (event) => {
    let link = event.target;

    if (!link.hasAttribute('target')) {
      return;
    }

    event.preventDefault();

    let w = 450;
    let h = 350;

    let options =
      'height=' +
      h +
      ', width=' +
      w +
      ', top=' +
      (screen.height / 3 - h / 2) +
      ', left=' +
      (screen.width / 2 - w / 2) +
      ', toolbar=0, location=0, menubar=0' +
      ', directories=0, scrollbars=0';

    window.open(link.href, 'share', options);
  };
}
