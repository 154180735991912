import { defaultMemoize } from 'reselect';

import metrics from '../calibreweb-semibold-metrics.json';

const DEFAULT_PADDING = 4;

// This function can't be usefully memoized as lines will often be a new array
export function measureText(lines, fontSize, padding = DEFAULT_PADDING) {
  // console.log('measure', lines);

  let text = '';
  if (lines.length > 1) {
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > text.length) {
        text = lines[i];
      }
    }
  } else {
    text = lines[0];
  }

  let width = 0;
  let i = text.length;
  while (i--) {
    if (metrics[text[i]]) {
      width += metrics[text[i]] * fontSize;
    }
  }

  const finalWidth = width + padding;
  const finalHeight = lines.length * fontSize + padding;

  return {
    width: finalWidth,
    height: finalHeight,
    hwidth: finalWidth / 2,
    hheight: finalHeight / 2,
  };
}

// Add separate function the accepts only simple values so it can be memoized
export function measureTextString(str, fontSize, padding = DEFAULT_PADDING) {
  const lines = str.split('\\n');
  return measureText(lines, fontSize, padding);
}

export const memoizedMeasureTextString = defaultMemoize(measureTextString);
