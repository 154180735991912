import React, { Component } from 'react';

export const TabsContext = React.createContext({
    registerTab: function () {
    },
    registerPanel: function () {
    },
    activate: function () {
    },
    isActive: function () {
    }
});

export default class TabsContainer extends Component {
    constructor (props) {
        super();

        this.state = {
            activeTabIndex: props.activeTabIndex || 0,
        };
        this.totalPanels = 0;
        this.totalTabs = 0;
    }

    // Provide a simple tab controller via React context
    // XXX: This assumes that tabs and panels are registered in JSX order
    _getChildContext () {
        let _this = this;
        // let totalPanels = 0;

        return {
            registerTab: function () {
                _this.totalTabs ++;
                return _this.totalTabs - 1;
            },

            registerPanel: function () {
                _this.totalPanels ++;
                return _this.totalPanels - 1;
            },

            activate: function (index) {
                _this.setState({ activeTabIndex: index });

                if (_this.props.handleActivate) {
                    _this.props.handleActivate();
                }
            },

            isActive: function (index) {
                return _this.state.activeTabIndex === index;
            }
        };
    }

    render (props) {
        return (
            <div>
                <TabsContext.Provider value={this._getChildContext()}>
                    { props.children }
                </TabsContext.Provider>
            </div>
        )
    }
}
