import React from 'react';

import ShareList from '../share-list/share-list';
import ShareFields from '../ShareFields';

import './styles.scss';

export default function SharePopover(props) {
  let url = window.location.href.replace(/\?.*/, '');
  let embedURL = `${window.location.protocol}//${window.location.host}${__config.embed_path}`;

  if (props.queryString !== undefined) {
    url += '?' + props.queryString;
    embedURL += '?' + props.queryString;
  }

  return (
    <div class="share-popover">
      {props.queryString !== undefined && <ShareFields url={url} embedURL={embedURL} />}
      <ShareList kind="popover" url={url} message={props.message} />
    </div>
  );
}
