let Sifter = require('sifter');
const arrayWithout = require('lodash/without');
const clone = require('lodash/clone');

export default class CollectionTree {
    constructor (data) {
        this.parseData(data);

        this.sifter = new Sifter(this.collection);
    }

    parseData (data) {
        this.index = {};

        let rootNode = {
            id: null,
            model: null,
            parent: null,
            children: null,
            isRoot: true
        };

        this.tree = rootNode;
        this.collection = [rootNode];

        this.defaultNode = rootNode;

        this.index[rootNode.id] = rootNode;

        this.parseNodes(data);
    }

    // XXX: This probably assumes category data is sorted by depth
    parseNodes (data) {
        let complete = false;

        let reparentNodes = [];

        while (!complete) {
            let unparsedData = [];

            data.forEach(function (datum) {
                let node = {
                    id: datum.id,
                    model: datum,
                    children: null
                };

                let parentNode = this.index[datum.parent];

                if (!parentNode) {
                    unparsedData.push(datum);
                    return;
                }

                node.parent = parentNode;
                node.depth = (parentNode.depth || 0) + 1;

                if (!parentNode.children) {
                    parentNode.children = [];
                }
                parentNode.children.push(node);

                this.collection.push(node);

                this.index[datum.id] = node;

                if (parentNode.model && parentNode.model.name === node.model.name) {
                    reparentNodes.push(node);
                }
            }, this);

            if (unparsedData.length) {
                data = unparsedData;
            } else {
                complete = true;
            }
        }

        // TODO: Investigate if this needs to be a seperate loop
        reparentNodes.forEach(function (node) {
            let parent = node.parent;
            let grandparent = parent.parent;

            if (parent.children.length > 1) {
                return;
            }

            // Remove parent node
            grandparent.children = arrayWithout(grandparent.children, parent);
            this.collection = arrayWithout(this.collection, parent);
            delete this.index[parent.id];

            // Set node as child of grandparent
            // FIXME: Need to update depth of node and its children?
            node.parent = grandparent;
            node.model = clone(node.model);
            node.model.parent = grandparent.id;
            grandparent.children.push(node);
        }, this);
    }

    setDefault (data) {
        this.defaultNode.id = data.id || null;
        this.defaultNode.model = data;
    }

    get (id) {
        return this.index[id];
    }

    getDefault () {
        return this.defaultNode;
    }

    contains (node) {
        return !!this.get(node.id);
    }

    search (term) {
        if (!term || term.trim() === '') {
            throw new Error();
        }

        let results = this.sifter.search(term, {
            fields: ['model.name'],
            conjunction: 'and',
            nesting: true
        });

        let tree = [];
        let lookup = {};

        // Create temporary tree nodes
        results.items.forEach(function (result) {
            let node = this.collection[result.id];
            let label = node.model.name.replace(results.tokens[0].regex, '<em>$&</em>');
            let score = result.score / Math.pow(10, node.depth);

            lookup[node.id] = {
                node: node,
                parent: node.parent ? node.parent.id : null,
                children: [],
                label: label,
                score: score
            };
        }, this);

        // Create temporary tree
        results.items.forEach(function (result) {
            let node = this.collection[result.id];
            let item = lookup[node.id];

            if (item.parent && lookup[item.parent]) {
                lookup[item.parent].children.push(item);
            } else {
                tree.push(item);
            }
        }, this);

        // Flatten temporary tree
        function flatten (tree, depth = 0) {
            let flat = [];

            tree.sort(function (a, b) {
                if (a.score > b.score) {
                    return -1;
                }

                if (a.score < b.score) {
                    return 1;
                }

                return 0;
            });

            tree.forEach(function (item) {
                flat.push({
                    label: item.label,
                    node: item.node,
                    depth: depth
                });

                if (item.children.length) {
                    flat = flat.concat(flatten(item.children, depth + 1));
                }
            });

            return flat;
        }

        return {
            collection: flatten(tree)
        };
    }

    toJSON () {
        return this.collection.reduce(function (memo, node) {
            if (node.model) {
                memo.push(node.model);
            }

            return memo;
        }, []);
    }
};
