import React, { useRef, useState, useEffect } from 'react';
import { removeChildrenFromTab, addChildrenToTab } from 'utilities/tabindex.js';

import './styles.scss';

import Icon from '../icon/icon';

export default function DisclosurePanel({ children, icon_id, type }) {
  const triggerNode = useRef(null);
  const contentNode = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const handleClickTrigger = () => {
    if (open) {
      animateClose();
    } else {
      animateOpen();
    }
  };

  const animateOpen = () => {
    if (open) {
      return;
    }

    const contentHeight = contentNode.current.scrollHeight;
    setHeight(contentHeight);
    setOpen(true);

    contentNode.current.addEventListener('transitionend', handleTransEnd);
    addChildrenToTab(contentNode.current);
  };

  const animateClose = () => {
    requestAnimationFrame(() => {
      const contentHeight = contentNode.current.scrollHeight;
      setHeight(contentHeight);
      requestAnimationFrame(() => {
        setHeight(0);
      });
    });

    setOpen(false);
    removeChildrenFromTab(contentNode.current);
  };

  const handleTransEnd = () => {
    setHeight('auto');
    contentNode.current.removeEventListener('transitionend', handleTransEnd);
  };

  useEffect(() => {
    removeChildrenFromTab(contentNode.current);
  }, []);

  return (
    <div class={`disclosure-panel disclosure-panel--${type}`} aria-expanded={open} data-type={type}>
      <div class="disclosure-panel__header">
        <button
          type="button"
          class="disclosure-panel__button"
          ref={triggerNode}
          onClick={handleClickTrigger}
        >
          {icon_id && (
            <span class="disclosure-panel__icon">
              <Icon id={icon_id} />
            </span>
          )}
          {children[0]}
        </button>
      </div>
      <div class="disclosure-panel__content" ref={contentNode} style={{ height: height }}>
        {children[1]}
      </div>
    </div>
  );
}

// import { addChildrenToTab, removeChildrenFromTab } from 'src/utils/tabindex.js';

// function getLocationHashTarget() {
//   const hash = window.location.hash.slice(1);
//   return document.querySelector(`[name="${hash}"]`);
// }

// export class DisclosurePanelBehaviour {
//   static id = 'disclosure-panel';

//   constructor(node) {
//     this.node = node;
//     this.triggerNode = node.querySelector('[data-ref="disclosure-trigger"]');
//     this.contentNode = node.querySelector('[data-ref="disclosure-content"]');

//     if (node.dataset.type === 'footnotes') {
//       const targetNode = getLocationHashTarget();
//       if (this.contentNode.contains(targetNode)) {
//         this.animateOpen();
//       }

//       window.addEventListener('hashchange', this.handleHashChange);
//     }

//     this.triggerNode.addEventListener('click', this.handleClickTrigger);
//   }

//   handleHashChange = () => {
//     const targetNode = getLocationHashTarget();
//     if (this.contentNode.contains(targetNode)) {
//       this.animateOpen();
//     }
//   };

// }
