import React, { Component } from 'react';

import Icon from '../icon/icon';
import FilterPopover from '../FilterPopover';

import './_query-select.scss';

export default class QuerySelect extends Component {
    constructor (props) {
        super();

        this.state = {
            showPopover: false,
            rect: null
        };

        this.lastWindowWidth = window.innerWidth;
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleDocumentMouseDown);
        document.addEventListener('touchstart', this.handleDocumentMouseDown);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleDocumentMouseDown);
        document.removeEventListener('touchstart', this.handleDocumentMouseDown);
    }

    render (props, state) {
        var data, clear;

        data = props.data;

        if (props.default && props.current !== props.default) {
            clear = (
                <button
                    className="queryselect__clear"
                    type="button"
                    aria-label={ `Clear ${props.label || ''}` }
                    onClick={ this.handleClearClick }
                >
                    <Icon id="close" />
                </button>
            );
        }

        let className = 'queryselect';
        if (props.id) {
            className += ` queryselect--${props.id}`;
        }

        let maxPopoverHeight = 530;
        if (state.rect) {
            maxPopoverHeight = Math.min(530, window.innerHeight - state.rect.bottom - 16);
        }

        return (
            <div class={ className }>
                <div className="queryselect__body">
                    <h1>{ props.label }</h1>
                    <div className="queryselect__current">
                        <h2 className="queryselect__current__value">
                            { props.current.model.name }
                        </h2>
                        <button
                            type="button"
                            title={ props.current.model.name }
                            onClick={ this.handleEditClick }
                        >
                            { `Edit ${props.label || ''}` }
                        </button>
                    </div>
                    { clear }
                </div>
                { this.state.showPopover && <FilterPopover
                    data={data}
                    label={props.label}
                    current={props.current}
                    searchable={props.searchable}
                    maxHeight={maxPopoverHeight}
                    modifier={props.id}
                    showIcons={props.showIcons}
                    reverse={props.reverse}
                    noWrap={props.noWrap}
                    inline={props.inline}
                    onClose={this.handlePopoverClose}
                    onTreeClick={this.handleTreeClick}
                />}
            </div>
        );
    }

    componentDidUpdate () {
        if (!this.focusSearchInput) {
            return;
        }

        let input = this.base.querySelector('input');

        if (input) {
            input.focus();
        }

        this.focusSearchInput = false;
    }

    handleEditClick = () => {
        if (!this.state.showPopover) {
            this.focusSearchInput = true;
        }

        if (!this.state.showPopover) {
            this.measure();
        }

        if (this.state.showPopover) {
            this.closePopover();
        } else {
            this.openPopover();
        }
    }

    handleClearClick = () => {
        this.props.handleSelect(this.props.default);
    }

    handleTreeClick = (item) => {
        this.closePopover(true);
        this.props.handleSelect(item);
    }

    handleDocumentMouseDown = (event) => {
        if (!this.state.showPopover) {
            return;
        }

        if (this.base && this.base.contains(event.target)) {
            return;
        }

        this.closePopover();
    }

    handlePopoverClose = () => {
      this.closePopover();
    }

    openPopover = () => {
        console.log('open');

        this.setState({
            showPopover: true
        });

        if (this.props.handlePopoverToggle) {
            this.props.handlePopoverToggle(true);
        }
    }

    closePopover = (reset) => {
        console.log('close');

        let state = {
            showPopover: false
        };

        this.setState(state);

        if (this.props.handlePopoverToggle) {
            this.props.handlePopoverToggle(false);
        }
    }

    measure = () => {
        if (!this.base) {
            return;
        }

        let rect = this.base.getBoundingClientRect();

        this.setState({ rect: rect });
    }
}
