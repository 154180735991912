import React, { Component } from 'react';

import { TabsContext } from '../../components/tabs/tabs';

import './_tabpanel.scss';

export default class Tabpanel extends Component {
    static contextType = TabsContext;

    render (props, state, context) {
        if (this.index === undefined) {
            this.index = context.registerPanel();
        }

        return (
            <div id={ 'tabpanel' + this.index } className="tabpanel" role="tabpanel"
                aria-hidden={ (!context.isActive(this.index)).toString() }>
                { props.children }
            </div>
        )
    }
}
