import models from '../../utilities/models.js';
import CollectionFlat from '../../../shared/collection-flat';
import SimpleTreeCollection from '../../../shared/SimpleTreeCollection';

import { rehydrateMapData } from './rehydrate.js';
import { createYearRangeModels, stateToQuerystring, querystringToState } from './utils.js';

import { POLICIES_SINGLE_YEAR_THRESHOLD } from '../../constants.js';

const DATASET_PROCESSORS = {
  map: rehydrateMapData,
};

// TODO: Remove in favourof getting collection via state
let yearsCollection;

export const initialState = {
  // query: null,
  results: {
    map: {
      status: null,
      data: null,
    },
    country: {
      status: null,
      data: null,
    },
  },
  types: {
    status: null,
    data: null,
  },
  years: null,
  regions: null,
  activeType: null,
  hoveredType: null,
  activeYear: null,
  activeCountryId: null,
  activeCountry: null,
  hoveredCountryId: null,
  hoveredCountry: null,
  overlayVisible: false,
  activeMobileTab: 'map',
  activeRegion: null,
};

export function getInitialState(policyModels) {
  const typesCollection = new SimpleTreeCollection(policyModels.types);

  yearsCollection = new CollectionFlat(
    createYearRangeModels(
      policyModels.years[0],
      policyModels.years[1],
      POLICIES_SINGLE_YEAR_THRESHOLD
    )
  );
  yearsCollection.setDefault({ id: null, name: 'All years' });

  const regionCollection = models.countryGroupsRegions;
  const flatRegionCollection = new CollectionFlat(regionCollection.toJSON());
  flatRegionCollection.setDefault({ id: null, name: 'World' });

  const state = {
    ...initialState,
    types: {
      status: 'ready',
      data: typesCollection,
    },
    years: yearsCollection,
    regions: flatRegionCollection,
    activeType: typesCollection.getDefault(),
    activeYear: yearsCollection.getDefault(),
    activeRegion: flatRegionCollection.getDefault(),
  };

  const queryState = querystringToState(state, yearsCollection, flatRegionCollection);

  return {
    ...state,
    ...queryState,
  };
}

function updateUrl(state) {
  window.history.pushState(
    {},
    document.title,
    window.location.href.replace(/\?.*/, '') + '?' + stateToQuerystring(state)
  );
}

export function reducer(state, action) {
  switch (action.type) {
    case 'RESULTS_REQUESTED': {
      const results = {
        ...state.results,
        [action.id]: {
          status: 'pending',
          data: null,
        },
      };

      return {
        ...state,
        results,
      };
    }

    case 'RESULTS_LOADED': {
      const results = {
        ...state.results,
        [action.id]: {
          status: 'ready',
          data: DATASET_PROCESSORS[action.id]
            ? DATASET_PROCESSORS[action.id](action.data, models)
            : action.data,
        },
      };

      return {
        ...state,
        results,
      };
    }

    case 'SET_ACTIVE_TYPE': {
      const newState = {
        ...state,
        activeType: action.data,
        hoveredType: null,
      };
      updateUrl(newState);
      return newState;
    }

    case 'SET_HOVERED_TYPE': {
      const newState = {
        ...state,
        hoveredType: action.data,
      };
      return newState;
    }

    case 'SET_ACTIVE_YEAR': {
      const newState = {
        ...state,
        activeYear: action.data,
      };
      updateUrl(newState);
      return newState;
    }

    case 'SET_ACTIVE_COUNTRY':
      return {
        ...state,
        activeCountryId: action.id,
        activeCountry: models.countries.get(action.id),
      };

    case 'SET_HOVERED_COUNTRY':
      return {
        ...state,
        hoveredCountryId: action.id,
        hoveredCountry: models.countries.get(action.id),
      };

    case 'HISTORY_POPSTATE': {
      console.log('HISTORY_POPSTATE');
      return {
        ...state,
        ...querystringToState(state, yearsCollection, state.regions),
      };
    }

    case 'SET_OVERLAY_VISIBLE': {
      return {
        ...state,
        overlayVisible: action.visible || false,
      };
    }

    case 'SET_ACTIVE_MOBILE_TAB': {
      return {
        ...state,
        activeMobileTab: action.id,
      };
    }

    case 'SET_ACTIVE_REGION': {
      const newState = {
        ...state,
        activeRegion: action.data,
      };
      updateUrl(newState);
      return newState;
    }

    default:
      console.warn('Action not implemented');
  }
}
