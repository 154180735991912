export function rehydrateMapData(data, models) {
  return data.map(function (datum) {
    const country = models.countries.get(datum.id);

    if (!country) {
      console.warn('Missing country data', datum.id);
    }

    return {
      ...datum,
      country: country,
    };
  });
}
