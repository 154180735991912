import {
  POLICIES_EXTRA_COUNTRY_MODELS,
  POLICIES_COUNTRY_MODEL_OVERRIDES,
  POLICIES_EXTRA_COUNTRYGROUPS,
} from '../constants.js';

export function tweakPoliciesCountries(rawModelsData) {
  rawModelsData.countries.push(...POLICIES_EXTRA_COUNTRY_MODELS);

  POLICIES_COUNTRY_MODEL_OVERRIDES.forEach((override) => {
    const country = rawModelsData.countries.find((c) => c.id === override.id);
    if (country) {
      Object.assign(country, override);
    }
  });
}

export function tweakPoliciesCountryGroups(rawModelsData) {
  // Create new 'Asia' region by combining 'Central and Southern Asia', 'Eastern and South-Eastern Asia'
  // and 'China'
  const asiaGroups = rawModelsData.countryGroups.filter((group) => {
    return ['csa', 'chn', 'esa'].includes(group.id);
  });
  const asiaGroup = {
    id: 'asa',
    name: 'Asia',
    type: 'region',
    children: asiaGroups.reduce((acc, group) => {
      acc = acc.concat(group.children);
      return acc;
    }, []),
  };

  // Remove unpopulated regions and add Africa region
  // FIXME: Make this dynamic
  rawModelsData.countryGroups = rawModelsData.countryGroups.filter((group) => {
    return ['eur', 'lac', 'nac'].includes(group.id);
  });

  rawModelsData.countryGroups.unshift(POLICIES_EXTRA_COUNTRYGROUPS.afr);
  rawModelsData.countryGroups.unshift(asiaGroup);

  // Manually add EU 27 meta-country to Europe region
  // (as adding it in the API would break aggregates for the region)
  const europeCountryGroup = rawModelsData.countryGroups.find((group) => group.id === 'eur');
  europeCountryGroup.children.push(97);

  // Manually add extra country models (Puerto Rico and Taiwan) to their respective groups
  const latinAmericaCountryGroup = rawModelsData.countryGroups.find((group) => group.id === 'lac');
  latinAmericaCountryGroup.children.push(630);
  const asiaCountryGroup = rawModelsData.countryGroups.find((group) => group.id === 'asa');
  asiaCountryGroup.children.push(158);
}
