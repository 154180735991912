import React from 'react';

import './styles.scss';

import Icon, { CircleIcon } from '../icon/icon';
import PolicyIcon from '../PolicyIcon';
import DisclosurePanel from '../DisclosurePanel';
import StatusFlag from '../StatusFlag';

import { formatFriendlyUrl, formatYearSpan } from '../../utilities/formatters.js';

export default function PoliciesList({ policies }) {
  return (
    <ul className="policies-list">
      {policies.map((policy) => {
        return (
          <li style={{ '--accent-colour': policy.type.model.icon?.colour }}>
            <div className="policies-list__header">
              <PolicyIcon options={policy.type.model.icon} />
              <h1>
                {policy.type.model.name} ({policy.items.length})
              </h1>
            </div>
            <ul className="policies-list__list">
              {policy.items.map((item) => {
                return <PolicyListItem item={item} />;
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}

function PolicyListItem({ item }) {
  return (
    <li class="policy-list-item">
      <DisclosurePanel icon_id="accordion" type="accordion">
        <div className="policy-list-item__header">
          <h1>{item.name}</h1>
          {item.status && <StatusFlag status={item.status} />}
          <p>
            {item.ministry && `${item.ministry.trim()}, `}
            {formatYearSpan(item.startYear, item.endYear)}
          </p>
        </div>
        <div className="policy-list-item__body">
          {/* FIXME: This will be rich text from CMS? */}
          {/* <p>{item.description}</p> */}
          <div
            className="policy-list-item__desc"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
          {item.sources && (
            <div className="policy-list-item__sources">
              {item.description && item.description.indexOf('<h4>') !== -1 ? (
                <h4>Additional resources</h4>
              ) : undefined}
              <ul>
                {item.sources.map((source) => {
                  return (
                    <li>
                      <a className="policy-list-item__source" href={source} target="_blank">
                        <Icon id="external-link" />
                        <span>{formatFriendlyUrl(source)}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {item.relatedPublications && (
            <div class="policy-list-item__publications">
              <h2>Related publications</h2>
              <ul>
                {item.relatedPublications.map((publication) => {
                  return (
                    <li>
                      <a
                        className="policy-list-item__publication"
                        href={publication.url}
                        target="_blank"
                      >
                        <span>{publication.title}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </DisclosurePanel>
    </li>
  );
}
