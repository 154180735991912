import React from 'react';
import clsx from 'clsx';

import './styles.scss';

export default function Dashboard({ chart, tabs }) {
  return (
    <div className={clsx('dashboard', chart && 'dashboard--with-chart')}>
      <div className="dashboard__inner">
        {chart && <div className="dashboard__chart">{chart}</div>}
        <div className="dashboard__tabs">{tabs}</div>
      </div>
    </div>
  );
}
