import React from 'react';
import clsx from 'clsx';

import './styles.scss';

export default function PolicyMarker({
  x,
  y,
  highlighted = false,
  hollow = false,
  hovered,
  onHover,
  onClick,
  r = 4,
  colour,
}) {
  return (
    <circle
      className={clsx(
        'policy-marker',
        highlighted && 'policy-marker--highlighted',
        hollow && 'policy-marker--hollow',
        hovered && 'policy-marker--hovered',
        hovered === false && 'policy-marker--unhovered',
        onClick && 'policy-marker--interactive'
      )}
      style={{ color: colour }}
      cx={x}
      cy={y}
      r={r}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      onClick={onClick}
    />
  );
}

export function PolicyMarkerIcon({ r = 4, highlighted = false, hollow = false, colour }) {
  const d = r * 4;
  return (
    <svg className="policy-marker-icon" width={d} height={d}>
      <PolicyMarker x={d / 2} y={d / 2} highlighted={highlighted} hollow={hollow} colour={colour} />
    </svg>
  );
}
