export default class SimpleTreeCollection {
  constructor(data) {
    // If no data return a stub instance for use in manual construction (e.g. for filtering)
    if (!data) {
      return this;
    }

    this.parseData(data);
  }

  parseData(data) {
    this.index = {};

    this.collection = data.map(function (datum) {
      let children = null;

      if (datum.children && datum.children.length) {
        children = datum.children.reduce(function (memo, child, i) {
          let childNode = {
            id: child.id,
            index: i,
            model: child,
          };

          memo.push(childNode);
          this.index[child.id] = childNode;

          return memo;
        }.bind(this), []);
      }

      let node = {
        id: datum.id,
        model: datum,
        children: children,
      };

      this.index[datum.id] = node;

      return node;
    }, this);
  }

  get(id) {
    return this.index[id];
  }

  getDefault() {
    return this.collection[0];
  }

  getBranch(id) {
    return this.collection.find(function (item) {
      return item.id === id || item.children.find(function (child) {
        return child.id === id;
      });
    });
  }

  contains(node) {
    return !!this.get(node.id);
  }

  toJSON() {
    return this.collection.map(function (node) {
      return node.model;
    });
  }

  forEach(callback) {
    this.collection.forEach(function (node) {
      callback(node);
    });
  }
}
