import React from 'react';

import './styles.scss';

const StatusFlag = ({status}) => {
    return (
        <div className="status-flag">{status}</div>
    );
}

export default StatusFlag;
