import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

// TODO: Add these helpers from Forest's project?
// import { getPointerType, eventMatchesPointerType } from '../../../utils/pointer.js';
// import { supportsPassiveEvents } from '../../../utils/supports.js';

import './styles.scss';

const GUTTER_WIDTH = 8;
const TARGET_NODE_ID = 'tooltips-portal';

export default class PortalTooltip extends Component {
  constructor() {
    super();

    this.state = {
      x: null,
      y: null,
      width: null,
    };

    let targetNode = document.getElementById(TARGET_NODE_ID);
    if (!targetNode) {
      targetNode = document.createElement('div');
      targetNode.id = TARGET_NODE_ID;
      document.body.appendChild(targetNode);
    }
    this.targetNode = targetNode;

    this.contentNode = React.createRef();
  }

  componentDidMount() {
    // This should catch the event which prompted the tooltip to be rendered
    // FIXME: This doesn't catch the touchstart event on old versions of iOS?
    document.addEventListener('mousemove', this.handlePointerMove);
    document.addEventListener(
      'touchstart',
      this.handlePointerMove
      // supportsPassiveEvents ? { passive: true } : false
    );
    document.addEventListener('TooltipPosition', this.handlePointerMove);
  }

  componentDidUpdate() {
    // XXX: Tooltip has a fixed width, so we only need to measure it once
    if (this.state.width === null) {
      this.measure();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handlePointerMove);
    document.removeEventListener('touchstart', this.handlePointerMove);
  }

  handlePointerMove = (event) => {
    // if (!eventMatchesPointerType(event)) {
    //   return;
    // }

    // Reject multitouch gestures
    if (event.touches && event.touches.length > 1) {
      return;
    }

    let x;
    let y;
    switch (event.type) {
      case 'touchstart':
        x = event.touches[0].pageX;
        y = event.touches[0].pageY;
        break;
      default:
        x = event.pageX;
        y = event.pageY;
    }

    this.setState({ x: x, y: y });
  };

  measure() {
    if (!this.contentNode.current) {
      return;
    }

    const rect = this.contentNode.current.getBoundingClientRect();

    this.setState({ width: rect.width });
  }

  render() {
    if (this.state.x === null || this.state.y === null) {
      return null;
    }

    const viewportWidth = document.documentElement.clientWidth;
    const maxX =
      this.state.width !== null ? viewportWidth - (this.state.width + GUTTER_WIDTH) : Infinity;
    const x = Math.min(this.state.x, maxX);

    return createPortal(
      <div
        className={clsx(
          'tooltip',
          this.props.variant && `tooltip--${this.props.variant}`
          // getPointerType() === 'touch' && 'portal-tooltip--touch'
        )}
        style={{
          left: x,
          top: this.state.y,
          visibility: this.state.width === null ? 'hidden' : 'visible',
        }}
      >
        <div className="tooltip__main" ref={this.contentNode}>
          {this.props.children}
        </div>
      </div>,
      this.targetNode
    );
  }
}
