const Sifter = require('sifter');

export default class CollectionParent {
  constructor(data, childCollection) {
    // If no data return a stub instance for use in manual construction (e.g. for filtering)
    if (!data) {
      return this;
    }

    this.parseData(data, childCollection);

    this.sifter = new Sifter(this.collection);
  }

  parseData(data, childCollection) {
    this.index = {};

    this.collection = data.map(function (datum) {
      let children = null;

      if (datum.children) {
        children = datum.children.reduce(function (memo, childId) {
          let node = childCollection.get(childId);

          if (!node) {
            return memo;
          }

          memo.push({
            id: node.id,
            model: node.model,
          });

          return memo;
        }, []);
      }

      let node = {
        id: datum.id,
        model: datum,
        children: children,
      };

      this.index[datum.id] = node;

      return node;
    }, this);
  }

  get(id) {
    return this.index[id];
  }

  contains(node) {
    return !!this.get(node.id);
  }

  search(term) {
    if (!term || term.trim() === '') {
      throw new Error();
    }

    let results = this.sifter.search(term, {
      fields: ['model.name', 'model.alt_name'],
      conjunction: 'and',
      nesting: true,
    });

    let nodes = results.items.map(function (result) {
      let node = this.collection[result.id];

      // Return a stub node with name overridden
      return {
        // id: node.id,
        // model: node.model,
        label: node.model.name.replace(results.tokens[0].regex, '<em>$&</em>'),
        node: node,
      };
    }, this);

    return {
      collection: nodes,
    };
  }

  filter(key, value) {
    let nodes = [];
    let index = {};

    this.collection.forEach(function (node) {
      if (node.model[key] !== value) {
        return;
      }

      nodes.push(node);
      index[node.id] = node;
    });

    let collection = new CollectionParent();

    collection.collection = nodes;
    collection.index = index;
    collection.sifter = new Sifter(collection.collection);

    return collection;
  }

  toJSON() {
    return this.collection.map(function (node) {
      return node.model;
    });
  }

  forEach(callback) {
    this.collection.forEach(function (node) {
      callback(node);
    });
  }
}
