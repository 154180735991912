import React from 'react';

import './styles.scss';

export default function MapActions({ status, children }) {
  return (
    <div className="map-actions">
      {status ? <p className="map-actions__status">{status}</p> : null}
      <div className="map-actions__items">{children}</div>
    </div>
  );
}
