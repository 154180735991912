import React, { Component } from 'react';
import { geoStream, geoClipAntimeridian, geoPath } from 'd3-geo';

import { getBoundsGeoPolygon } from '../../utilities/geometry.js';

export default function ZoomRegionsDebugLayer({ regionBounds, projection }) {
  return Object.keys(regionBounds).map((key) => {
    const bounds = regionBounds[key];
    const rect = bounds.map((point) => projection(point));
    const xMin = Math.min(rect[0][0], rect[1][0]);
    const xMax = Math.max(rect[0][0], rect[1][0]);
    const yMin = Math.min(rect[0][1], rect[1][1]);
    const yMax = Math.max(rect[0][1], rect[1][1]);
    console.log(key, bounds, rect);

    // const stream = projection.stream();
    // projection.preclip(geoClipAntimeridian);
    // geoStream({ type: 'Polygon', coordinates: bounds }, stream);

    const polygon = getBoundsGeoPolygon(bounds);
    const path = geoPath().projection(projection);
    const pathBounds = path.bounds(polygon);
    console.log(pathBounds);

    return (
      <g>
        {/*
        <rect
          x={rect[0][0]}
          y={rect[1][1]}
          width={rect[1][0] - rect[0][0]}
          height={rect[0][1] - rect[1][1]}
          style={{ fill: 'none', stroke: 'lime', strokeWidth: '1px' }}
        />
        */}
        {/*<rect
          x={xMin}
          y={yMin}
          width={xMax - xMin}
          height={yMax - yMin}
          style={{ fill: 'none', stroke: 'lime', strokeWidth: '1px' }}
        />*/}
        <path
          d={path(polygon)}
          style={{ fill: 'none', stroke: 'rgba(255,0,0,0.5)', strokeWidth: '1px' }}
        />
        <rect
          x={pathBounds[0][0]}
          y={pathBounds[0][1]}
          width={pathBounds[1][0] - pathBounds[0][0]}
          height={pathBounds[1][1] - pathBounds[0][1]}
          style={{ fill: 'none', stroke: 'rgba(0,0,255,0.5)', strokeWidth: '1px' }}
        />
        {/* <text x={xMin} y={yMin}>{key}</text> */}
        <text x={pathBounds[0][0]} y={pathBounds[0][1]}>
          {key}
        </text>
      </g>
    );
  });
}
