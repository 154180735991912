import React from 'react';

import './styles.scss';

import imageUrl from './map-bg.svg';

export default function MapBackground(props) {
  return (
    <g>
      <image href={imageUrl} {...props} />
    </g>
  );
}
