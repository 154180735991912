import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import models from '../../utilities/models.js';

import { POLICY_STATUSES } from '../../constants.js';

function activeYearPredicate(datum, activeYear) {
  return activeYear.model.range
    ? datum.startYear >= activeYear.model.range[0] && datum.startYear <= activeYear.model.range[1]
    : datum.startYear <= activeYear.id && (!datum.endYear || datum.endYear >= activeYear.id);
}

function getActiveTypeIds(activeType) {
  return activeType.children
    ? activeType.children.map(function (child) {
        return child.model.id;
      })
    : [activeType.model.id];
}

export const selectActiveYear = (state) => state.activeYear;
export const selectActiveType = (state) => state.activeType;
export const selectHoveredType = (state) => state.hoveredType;
export const selectMapResults = (state) => state.results.map;
export const selectCountryResults = (state) => state.results.country;
export const selectTypes = (state) => state.types.data;
export const selectCurrentYear = (state) => new Date().getFullYear();
export const selectActiveRegion = (state) => state.activeRegion;

export const selectMapData = createSelector(
  selectMapResults,
  selectActiveYear,
  selectActiveType,
  selectCurrentYear,
  function (results, activeYear, activeType, currentYear) {
    const activeTypeIds = getActiveTypeIds(activeType);

    return results.data.reduce(function (acc, datum) {
      const data = datum.data.filter(function (d) {
        return (
          (!activeType.id || activeTypeIds.includes(d.type)) &&
          (!activeYear.id || activeYearPredicate(d, activeYear))
        );
      });

      // Add new status to all policies from latest year
      // const formattedData = data.map((datum) => {
      //   return {
      //     ...datum,
      //     status: datum.startYear === currentYear ? POLICY_STATUSES.new : datum.status,
      //   };
      // });

      if (data.length) {
        acc.push({
          ...datum,
          data: data,
        });
      }
      return acc;
    }, []);
  }
);

// TODO: Merge with above selector?
export const selectFormattedMapData = createSelector(
  selectMapData,
  selectTypes,
  selectHoveredType,
  function (mapData, types, hoveredType) {
    return mapData.map(function (datum) {
      const displayedType = datum.data.length
        ? datum.data.reduce((displayedType, datum) => {
            const type = types.get(datum.type);
            if (!displayedType || type.index > displayedType.index) {
              return type;
            }
            return displayedType;
          }, null)
        : undefined;
      const hasHoveredType =
        hoveredType && datum.data.length
          ? datum.data.some((d) => d.type === hoveredType.id)
          : false;
      const displayedColour = hasHoveredType
        ? hoveredType.model.icon?.colour
        : displayedType
        ? displayedType.model.icon?.colour
        : undefined;

      return {
        ...datum,
        highlighted: datum.data.some((d) => !!d.status),
        hovered: hoveredType ? hasHoveredType : undefined,
        colour: displayedColour,
        count: datum.data.length,
      };
    });
  }
);

export const selectListData = createSelector(selectFormattedMapData, function (mapData) {
  const keyedMapData = keyBy(mapData, 'id');
  const listData = [];

  models.countryGroupsRegions.forEach((region) => {
    const regionItem = {
      model: region.model,
      children: [],
    };

    region.children.forEach((country) => {
      if (keyedMapData[country.id]) {
        regionItem.children.push({
          model: country.model,
          data: keyedMapData[country.id],
        });
      }
    });

    if (regionItem.children.length) {
      regionItem.children = sortBy(regionItem.children, 'model.name');
      listData.push(regionItem);
    }
  });

  return listData;
});

export const selectPopulatedRegionIds = createSelector(selectListData, function (listData) {
  return listData.map((region) => {
    return region.model.id;
  });
});

export const selectHoveredCountryId = (state) => state.hoveredCountryId;

export const selectTooltipData = createSelector(
  selectHoveredCountryId,
  selectFormattedMapData,
  selectTypes,
  function (id, mapData, types) {
    if (!id) {
      return null;
    }

    const mapDatum = mapData.find((datum) => parseInt(datum.id, 10) === id);
    const grouped = groupBy(mapDatum.data, 'type');

    let total = 0;
    const summary = Object.keys(grouped).map((type) => {
      const count = grouped[type].length;
      const statuses = grouped[type].reduce((acc, datum) => {
        if (datum.status && !acc.includes(datum.status)) {
          acc.push(datum.status);
        }
        return acc;
      }, []);

      total += count;

      return {
        type: types.get(type),
        count: count,
        // FIXME: Only picking first policy status, probably want to prioritize one status type
        status: statuses.length ? statuses[0] : null,
      };
    });

    return { data: summary, total: total };
  }
);

export const selectCountries = createSelector(selectFormattedMapData, function (mapData) {
  // Sort countries by highlighted status, so highlighted country labels are positioned first
  return mapData
    .sort((a, b) => {
      if (a.highlighted && !b.highlighted) {
        return -1;
      }
      if (!a.highlighted && b.highlighted) {
        return 1;
      }
      return 0;
    })
    .map((datum) => datum.country)
    .filter((country) => !!country);
});

export const selectHoveredCountries = createSelector(selectFormattedMapData, function (mapData) {
  return mapData
    .filter((datum) => datum.hovered)
    .map((datum) => datum.country)
    .filter((country) => !!country);
});

export const selectCountryData = createSelector(
  selectCountryResults,
  selectActiveYear,
  selectActiveType,
  selectTypes,
  selectCurrentYear,
  function (results, activeYear, activeType, types, currentYear) {
    if (!results.data) {
      return null;
    }

    if (results.status !== 'ready') {
      return null;
    }

    const activeTypeIds = getActiveTypeIds(activeType);

    const data = results.data.data.filter(function (d) {
      return (
        (!activeType.id || activeTypeIds.includes(d.type)) &&
        (!activeYear.id || activeYearPredicate(d, activeYear))
      );
    });

    // Add new status to all policies from latest year
    // const formattedData = data.map((datum) => {
    //   return {
    //     ...datum,
    //     status: datum.startYear === currentYear ? POLICY_STATUSES.new : datum.status,
    //   };
    // });

    // FIXME: This could be more efficent
    const groupedData = groupBy(data, 'type');

    const finalData = Object.keys(groupedData).map(function (key) {
      return {
        type: types.get(key),
        items: groupedData[key],
      };
    });

    return finalData;
  }
);

// FIXME: It would be more consistent to use the model system as in the map view?
export const selectActiveRegionCountries = createSelector(selectActiveRegion, function (region) {
  if (!region || !region.model || !region.model.children) {
    return [];
  }

  return region.model.children.reduce((acc, id) => {
    const model = models.countries.get(id);
    if (model) {
      acc.push(model);
    }
    return acc;
  }, []);
});

export const selectActiveRegionCountriesWithData = createSelector(
  selectMapResults,
  selectActiveRegionCountries,
  function (results, countries) {
    if (!countries.length) {
      return [];
    }

    return countries.filter((country) => {
      return !!results.data.find((d) => d.country && d.country.id === country.id);
    });
  }
);

export const selectActiveTypeHasDescription = createSelector(selectActiveType, function (type) {
  return !!(type.model.description || (type.children && type.children[0].model.description));
});
