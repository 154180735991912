'use strict';

function tens (value) {
    let suffix = '';
    let precision = (value > 0) ? 1 : 0;

    if (value >= 1e+12) {
        value = value / 1e+12;
        suffix = 'tn';
    } else if (value >= 1e+9) {
        value = value / 1e+9;
        suffix = 'bn';
    } else if (value >= 1e+6) {
        value = value / 1e+6;
        suffix = 'm';
    } else if (value >= 1e+3) {
        value = value / 1e+3;
        suffix = 'k';
    } else {
        precision = 0;
    }

    if (value.toFixed(0).length >= 3) {
        precision = 0;
    }

    let multiplier = Math.pow(10, precision);
    let str = (Math.round(value * multiplier) / multiplier).toString();

    return str + suffix;
}

function percentage (value) {
    if (value === undefined || value === null || isNaN(value)) {
        return '–';
    }

    if (value === 0) {
        return '0%';
    }

    if (Math.abs(value) < 0.001) {
        return '<0.1%';
    }

    let precision = (value !== 0 && Math.abs(value) < 0.1) ? 1 : 0;
    let multiplier = Math.pow(10, precision);

    return (Math.round(value * 100 * multiplier) / multiplier).toString() + '%';
}

function currency (value, prefix) {
    if (value === undefined || value === null || isNaN(value)) {
        return '–';
    }

    // Raw data is in units of $1000
    value = value * 1000;

    prefix = prefix || '$';

    return prefix + tens(value);
}

export default {
    null: function (value) {
        if (value === undefined || value === null || isNaN(value)) {
            return '–';
        }

        return value;
    },

    tens: function (value, suffix) {
        if (value === undefined || value === null || isNaN(value)) {
            return '–';
        }

        return tens(value) + (suffix ? ` ${suffix}` : '');
    },

    currency: currency,

    signedCurrency: function (value) {
        let sign = '';

        if (value && !isNaN(value)) {
            sign = (value > 0) ? '+' : '−';
        }

        return currency(Math.abs(value), `${sign}$`);
    },

    weight: function (value) {
        if (value === undefined || value === null || isNaN(value)) {
            return '–';
        }

        // Raw data is in units of 1000kg (or 1 tonne)
        // RK 2/11/16:
        // The weights (not values) are out by a factor of 1000 – what reads 1 trillion tonnes should be 1 billion
        // (the original units are 1000s of kgs (i.e. tonnes), so I imagine that’s what’s caused confusion)
        // value = value * 1000;

        return tens(value) + ' t';
    },

    percentage: percentage,

    signedPercentage: function (value) {
        let sign = '';
        if (value !== 0) {
            sign = (value > 0) ? '+' : '−';
        }
        return sign + percentage(value).replace('-', '');
    },

    score: function (value, max) {
        if (value === undefined || value === null || isNaN(value)) {
            return '–';
        }

        max = max || 100;

        return `${value}/${max}`;
    },

    thousands: function (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
};
