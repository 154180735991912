import React from 'react';
import clsx from 'clsx';

import './_icon.scss';

export default function Icon({ id, colour, highlighted }) {
  return (
    <svg
      class={clsx('icon', `icon--${id}`, highlighted && 'icon--highlighted')}
      style={{ fill: colour, color: colour }}
    >
      <use xlinkHref={`#icon-${id}`}></use>
    </svg>
  );
}

export function CircleIcon({ colour, highlighted }) {
  return (
    <svg
      viewBox="0 0 48 48"
      class={clsx('icon', 'icon--circle', highlighted && 'icon--highlighted')}
      style={{ fill: colour, color: colour }}
    >
      <circle cx="50%" cy="50%" r="50%" />
    </svg>
  );
}
