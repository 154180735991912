import React, { Component } from 'react';

import './_list.scss';

export default class List extends Component {
    render () {
        return (
            <div className="list" />
        );
    }

    renderEmpty () {
        return (
            <div className="list list--empty">
                <p>None</p>
            </div>
        );
    }

    handleClick (data) {
        if (!this.props.handleClick || !data || data.disabled) {
            return;
        }

        this.props.handleClick(data.original);
    }

    handleMouseEnter = (data) => {
        if (!this.props.handleHover || !data) {
            return;
        }

        this.props.handleHover(data.original, null);
    }

    handleMouseLeave = (event) => {
        if (!this.props.handleHover) {
            return;
        }

        this.props.handleHover(null);
    }
}
