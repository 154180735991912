export function removeChildrenFromTab(element) {

  const links = element.getElementsByTagName('a');
  const buttons = element.getElementsByTagName('button');
  const inputs = element.getElementsByTagName('input');

  Array.prototype.forEach.call(links, link => {
    link.setAttribute('tabindex', '-1');
  });

  Array.prototype.forEach.call(buttons, button => {
    button.setAttribute('tabindex', '-1');
  });

  Array.prototype.forEach.call(inputs, input => {
    input.setAttribute('tabindex', '-1');
  });
};

export function addChildrenToTab(element) {
  const links = element.getElementsByTagName('a');
  const buttons = element.getElementsByTagName('button');
  const inputs = element.getElementsByTagName('input');
  Array.prototype.forEach.call(links, link => {
    link.removeAttribute('tabindex');
  });

  Array.prototype.forEach.call(buttons, button => {
    button.removeAttribute('tabindex');
  });

  Array.prototype.forEach.call(inputs, input => {
    input.removeAttribute('tabindex');
  });
};
