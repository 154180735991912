let Sifter = require('sifter');

export default class CollectionFlat {
    constructor (data, searchable) {
        this.parseData(data);

        if (searchable) {
            this.sifter = new Sifter(this.collection);
        }
    }

    parseData (data) {
        this.index = {};

        this.collection = data.map(function (datum) {
            let node = {
                id: datum.id,
                model: datum,
                children: null
            };

            this.index[datum.id] = node;

            return node;
        }, this);
    }

    setDefault (data) {
        let defaultNode = {
            id: data.id || null,
            model: data,
            children: null,
            unsearchable: true
        };

        this.defaultNode = defaultNode;

        this.collection.unshift(defaultNode);

        if (data.id) {
            this.index[data.id] = defaultNode;
        }
    }

    get (id) {
        return this.index[id];
    }

    getDefault () {
        return this.defaultNode;
    }

    getLast () {
        return this.collection[this.collection.length - 1];
    }

    contains (node) {
        return !!this.get(node.id);
    }

    search (term) {
        if (!this.sifter || !term || term.trim() === '') {
            throw new Error();
        }

        let results = this.sifter.search(term, {
            fields: ['model.name', 'model.alt_name'],
            conjunction: 'and',
            nesting: true
        });

        let nodes = [];

        results.items.map(function (result) {
            let node = this.collection[result.id];

            if (node.unsearchable) {
                return;
            }

            // Return a stub node with name overridden
            nodes.push({
                // id: node.id,
                // model: node.model,
                label: node.model.name.replace(results.tokens[0].regex, '<em>$&</em>'),
                node: node
            });
        }, this);

        return {
            collection: nodes
        };
    }

    forEach (callback) {
        this.collection.forEach(function (node) {
            callback(node);
        });
    }

    map (callback) {
        return this.collection.map(function (node) {
            return callback(node);
        });
    }

    toJSON () {
        return this.collection.map(function (node) {
            return node.model;
        });
    }
};
