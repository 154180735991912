export const POLICY_STATUSES = {
  new: 'new',
  updated: 'updated',
};

// Extra country models used by policies app only
// FIXME: Add to database?
export const POLICIES_EXTRA_COUNTRY_MODELS = [
  {
    aggregate: false,
    alt_name: null,
    end_year: 2018,
    has_meta: false,
    id: 630,
    is_nes: false,
    lat: 18,
    lng: -66,
    map_name: null,
    map_short_name: null,
    name: 'Puerto Rico',
    start_year: 2000,
  },
  {
    aggregate: false,
    alt_name: null,
    end_year: 2018,
    has_meta: false,
    id: 158,
    is_nes: false,
    lat: 24,
    lng: 121,
    map_name: null,
    map_short_name: null,
    name: 'Taiwan',
    start_year: 2000,
  },
];

export const POLICIES_COUNTRY_MODEL_OVERRIDES = [{ id: 97, lat: 45, lng: -25, box: true }];

export const POLICIES_SINGLE_YEAR_THRESHOLD = 2010;

// TODO: This would probably be better in the database
export const LEGACY_COUNTRYGROUPS_MAP = {
  mea: 'nawa',
  eax: 'esa',
  sas: 'csa',
  sea: 'esa',
  cna: 'csa',
  sac: 'lac',
  cca: 'lac',
  tpp: 'cptpp',
  eu28: 'euu',
};

/*
export const TEST_REGION_BOUNDS = {
  anz: [
    [112.909729097291, -54.749449870904954],
    [-171.1871118711187, -8.54571794842947],
  ],
  csa: [
    [44.0230402304023, 3.229593478591056],
    [92.6307263072631, 55.389553251001274],
  ],
  chn: [
    [73.60813608136081, 18.21824729856675],
    [134.75114751147515, 53.5563336038048],
  ],
  esa: [
    [87.74187741877421, -10.91015465248401],
    [145.8320583205832, 52.11718674061743],
  ],
  eur: [
    [-68.370083700837, 12.032866992732437],
    [-169.7290972909729, 81.85492590394657],
  ],
  lac: [
    [-118.39978399783998, -55.89174014349518],
    [-34.80694806948068, 32.715612141121426],
  ],
  nawa: [
    [-17.098370983709827, 8.665020292390437],
    [59.837998379983816, 43.56910573418236],
  ],
  nac: [
    [-141.00081000810007, 32.259043232776094],
    [-11.424714247142475, 83.59960937500003],
  ],
  ocn: [
    [-180, -24.412789516020155],
    [180, 18.80675246939971],
  ],
  ssf: [
    [-25.342453424534227, -46.9634743806813],
    [57.793177931779326, 27.285393337683388],
  ],
};
*/

/*
export const TEST_REGION_BOUNDS = {
  anz: [
    [71.08716, -56.28945],
    [-156.45576, -7.4975],
  ],
  csa: [
    [44.03667, -3.7623],
    [92.68152, 55.38148],
  ],
  chn: [
    [73.5004, 13.57105],
    [134.7671, 53.54843],
  ],
  esa: [
    [74.28481, -11.52517],
    [155.16731, 52.14563],
  ],
  eur: [
    [-82.11509, -60.78304492098051],
    [-122.0366, 84.84199050998731],
  ],
  lac: [
    [-120.12904, -58.14883],
    [-28.34015, 32.71894],
  ],
  nawa: [
    [-17.35589, 8.14549],
    [61.45114, 43.57344],
  ],
  nac: [
    [-141.00116, 31.43417],
    [-5.7106, 86.15274255061354],
  ],
  ocn: [
    [128.97621, -31.75456],
    [-122.0366, 21.28731],
  ],
  ssf: [
    [-28.81604, -48.03306],
    [68.4673, 27.31569],
  ],
};
*/

/*
export const TEST_REGION_BOUNDS = {
  anz: [
    [135, -42],
    [174, -25],
  ],
  csa: [
    [53, 3.2],
    [90.5, 48],
  ],
  chn: [
    [105, 22.1578],
    [114.167, 35],
  ],
  esa: [
    [98, -8.83333],
    [138, 46],
  ],
  eur: [
    [-25, 35.9167],
    [100, 65],
  ],
  lac: [
    [-102, -51.75],
    [-55, 24],
  ],
  nawa: [
    [-13.5, 15.5],
    [57, 42],
  ],
  nac: [
    [-98.5795, 32.3333],
    [-40, 72],
  ],
  ocn: [
    [134, -25.0667],
    // [-130.1, 16],
    [180, 16],
  ],
  ssf: [
    [-24, -30],
    [57.5833, 20],
  ],
};
*/

/*
export const TEST_REGION_BOUNDS = {
  anz: [
    [113.18333183331833, -46.630161717554664],
    [174.36954369543696, -10.707042248391218],
  ],
  csa: [
    [49.99549995499956, 3.229593478591056],
    [92.6307263072631, 45.555093685311874],
  ],
  chn: [
    [113.47853478534785, 22.195431211187127],
    [118.1837818378184, 24.552923901426524],
  ],
  esa: [
    [87.74187741877421, -8.31135748216856],
    [133.37953379533798, 52.11718674061743],
  ],
  eur: [
    [-24.47484474844748, 32.64790800642383],
    [145.94365943659437, 70.06399044584197],
  ],
  lac: [
    [-117.1289712897129, -52.35723711158986],
    [-34.80694806948068, 32.715612141121426],
  ],
  nawa: [
    [-17.098370983709827, 8.665020292390437],
    [58.95238952389525, 43.56910573418236],
  ],
  nac: [
    [-133.09873098730986, 32.259043232776094],
    [-11.424714247142475, 83.59960937500003],
  ],
  ocn: [
    [131.13671136711366, -24.412789516020155],
    [-128.28908289082892, 18.80675246939971],
  ],
  ssf: [
    [-25.342453424534227, -34.78541015238277],
    [57.793177931779326, 24.995787785136415],
  ],
};
*/

/*
export const TEST_REGION_BOUNDS = {
  anz: [
    [113.18333183331833, -46.630161717554664],
    [174.36954369543696, -10.707042248391218],
  ],
  csa: [
    [44.0230402304023, 3.229593478591056],
    [92.6307263072631, 55.389553251001274],
  ],
  chn: [
    [73.60813608136081, 20.26325936712493],
    [134.75114751147515, 53.5563336038048],
  ],
  esa: [
    [87.74187741877421, -10.347689533457824],
    [133.37953379533798, 52.11718674061743],
  ],
  eur: [
    [-24.47484474844748, 35.819586316488184],
    [-169.7290972909729, 77.7301946681239],
  ],
  lac: [
    [-117.1289712897129, -53.883184147466466],
    [-34.80694806948068, 32.715612141121426],
  ],
  nawa: [
    [-17.098370983709827, 8.665020292390437],
    [59.837998379983816, 43.56910573418236],
  ],
  nac: [
    [-141.00081000810007, 32.259043232776094],
    [-11.424714247142475, 83.59960937500003],
  ],
  ocn: [
    [131.13671136711366, -24.412789516020155],
    [-128.28908289082892, 18.80675246939971],
  ],
  ssf: [
    [-25.342453424534227, -34.78541015238277],
    [57.793177931779326, 27.285393337683388],
  ],
};
*/

// XXX: Manually modified region bounds
export const TEST_REGION_BOUNDS = {
  anz: [
    [113.18333183331833, -46.630161717554664],
    [174.36954369543696, -10.707042248391218],
  ],
  csa: [
    [49.99549995499956, 3.229593478591056],
    [92.6307263072631, 45.555093685311874],
  ],
  chn: [
    [73.60813608136081, 20.26325936712493],
    [134.75114751147515, 53.5563336038048],
  ],
  esa: [
    [87.74187741877421, -8.31135748216856],
    [133.37953379533798, 52.11718674061743],
  ],
  eur: [
    [-24.47484474844748, 32.64790800642383],
    [145.94365943659437, 70.06399044584197],
  ],
  lac: [
    [-117.1289712897129, -52.35723711158986],
    [-34.80694806948068, 32.715612141121426],
  ],
  nawa: [
    [-17.098370983709827, 8.665020292390437],
    [58.95238952389525, 43.56910573418236],
  ],
  nac: [
    [-133.09873098730986, 32.259043232776094],
    [-11.424714247142475, 83.59960937500003],
  ],
  ocn: [
    [131.13671136711366, -24.412789516020155],
    // 180 is antimeridian, plus the amount we have rotated the projection by
    [180 + 11.5, 18.80675246939971],
  ],
  ssf: [
    [-25.342453424534227, -34.78541015238277],
    [57.793177931779326, 24.995787785136415],
  ],
};

// TODO: Remove
export const TEST_REGION_NAMES = {
  anz: 'Australia and New Zealand',
  csa: 'Central and Southern Asia',
  chn: 'China',
  esa: 'Eastern and South-Eastern Asia (excl. China)',
  eur: 'Europe',
  lac: 'Latin America and the Caribbean',
  nawa: 'Northern Africa and Western Asia',
  nac: 'Northern America',
  ocn: 'Oceania (excl. Australia and New Zealand)',
  ssf: 'Sub-Saharan Africa',
};

export const ZOOM_GROUPS_EXCLUDED_COUNTRIES = {
  // In the Oceania region these countries are on the other side of the anti-meridian, so we exclude
  // then from the zoom area (temporarily)
  ocn: [184, 258, 581, 612],
  // Western Asia NES has no data for any year so we exclude it from the zoom area
  nawa: [879],
};

// Countries in the Oceania region which are on the wrong side of the anti-meridian (map projection edge)
export const ZOOM_ANTIMERIDIAN_COUNTRIES = [
  184, // Cook Islands
  258, // French Polynesia
  581, // United States Minor Outlying Islands
  612, // Pitcairn
];

export const POLICIES_EXTRA_COUNTRYGROUPS = {
  afr: {
    id: 'afr',
    name: 'Africa',
    type: 'region',
    children: [
      12,
      818,
      434,
      504,
      729,
      788,
      732,
      86,
      108,
      174,
      262,
      232,
      231,
      260,
      404,
      450,
      454,
      480,
      175,
      508,
      638,
      646,
      690,
      706,
      728,
      800,
      834,
      894,
      716,
      24,
      120,
      140,
      148,
      178,
      180,
      226,
      266,
      678,
      72,
      748,
      426,
      516,
      710,
      204,
      854,
      132,
      384,
      270,
      288,
      324,
      624,
      430,
      466,
      478,
      562,
      566,
      654,
      686,
      694,
      768,
    ],
  },
};

export const POLICIES_EU27_COUNTRYGROUP_COUNTRY_IDS = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];
