import React, { Component } from 'react';

import List from '../list/list';

import './_list-bars.scss';

export default class ListBars extends List {
    render (props) {
        if (!props.data.length) {
            return this.renderEmpty();
        }

        let max = props.data.reduce(function (memo, datum) {
            return Math.max(memo, Math.abs(datum.value));
        }, 0);

        return (
            <ol className="list">
                { props.data.map(function (datum, i) {
                    let className = 'list__item';
                    if (datum.disabled) {
                        className += ' list__item--disabled';
                    }

                    return (
                        <li
                            class={ className }
                            onClick={ this.handleClick.bind(this, datum) }
                            onMouseEnter={ this.handleMouseEnter.bind(this, datum) }
                            onMouseLeave={ this.handleMouseLeave }
                        >
                            <div
                                class={ 'list__bar' + (props.type ? ` list__bar--${props.type}` : '') }
                                style={{ width: Math.round(Math.abs(datum.value) / max * 100) + '%' }}
                            />
                            <div className="list__row">
                                <div className="list__cell list__cell--counter">
                                    { i + 1 }
                                </div>
                                <div className="list__cell list__cell--title" title={ datum.label }>
                                    <span>{ datum.label }</span>
                                </div>
                                <div className="list__cell list__cell--value">
                                    { datum.formattedValue }
                                </div>
                            </div>
                        </li>
                    );
                }, this) }
            </ol>
        );
    }
}
