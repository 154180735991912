import React from 'react';
import clsx from 'clsx';

import { PolicyMarkerIcon } from '../PolicyMarker';
import PolicyLabel from '../PolicyLabel';
import PolicyIcon from '../PolicyIcon';

import './styles.scss';

export default function PolicyMarkerLegend({ policyType, activePolicyType, hoverable, onClick, onHover }) {
  if (!policyType) {
    return null;
  }
  const childPolicyMarkerColours = (policyType.children || []).reduce((acc, child) => {
    if (child.model.icon?.colour && !acc.includes(child.model.icon.colour)) {
      acc.push(child.model.icon.colour);
    }
    return acc;
  }, []);
  const displayChildPolicyMarkers = childPolicyMarkerColours.length > 1;
  const markerColour =
    childPolicyMarkerColours.length === 1
      ? childPolicyMarkerColours[0]
      : policyType.model.icon?.colour;

  const handleClick = onClick ? (model) => () => {
    onClick(model === activePolicyType ? null : model)
  } : undefined;
  const handleHover = onHover && hoverable
    ? (model) => {
        return (event) => {
          if (event.type === 'mouseenter') {
            onHover(model);
          } else {
            onHover(null);
          }
        };
      }
    : undefined;

  return (
    <div
      class={clsx(
        'policy-marker-legend',
        activePolicyType && activePolicyType !== policyType && 'policy-marker-legend--active'
      )}
      aria-hidden="true"
    >
      <ul class="policy-marker-legend__list">
        {displayChildPolicyMarkers ? (
          <>
            {policyType.children.map((child) => {
              return (
                <PolicyMarkerLegendItem
                  model={child}
                  active={child === activePolicyType}
                  onClick={handleClick}
                  onHover={handleHover}
                >
                  <PolicyLabel policyType={child}>{child.model.name}</PolicyLabel>
                </PolicyMarkerLegendItem>
              );
            })}
            {/* <PolicyMarkerLegendItem>
              <PolicyMarkerIcon colour="#AAAAAA" highlighted hollow />
              <span>{__('policies-map-legend')}</span>
            </PolicyMarkerLegendItem> */}
          </>
        ) : (
          <>
            {policyType.children.map((child) => {
              return (
                <PolicyMarkerLegendItem
                  model={child}
                  active={child === activePolicyType}
                  onClick={handleClick}
                  onHover={handleHover}
                >
                  <PolicyIcon options={child.model.icon} />
                  <span>{child.model.name}</span>
                </PolicyMarkerLegendItem>
              );
            })}
            {/* <PolicyMarkerLegendItem>
              <PolicyMarkerIcon colour="#AAAAAA" highlighted hollow />
              <span>{__('policies-map-legend')}</span>
            </PolicyMarkerLegendItem> */}
          </>
        )}
      </ul>
    </div>
  );
}

function PolicyMarkerLegendItem({ model, active, onClick, onHover, children }) {
  const handleHover = onHover ? onHover(model) : undefined;

  return onClick ? (
    <li
      className={clsx('policy-marker-legend__item', active && 'policy-marker-legend__item--active')}
    >
      <button
        type="button"
        onClick={onClick(model)}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        {children}
      </button>
    </li>
  ) : (
    <li className="policy-marker-legend__item">{children}</li>
  );
}
