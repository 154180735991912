import React, { Component } from 'react';

import Icon from '../icon/icon';
import TabsContainer from '../../components/tabs/tabs';
import Tablist from '../tablist/tablist';
import Tab from '../tab/tab';
import Tabpanel from '../tabpanel/tabpanel';
import Tree from '../tree/tree';
import { ref } from '../../helpers';

import './styles.scss';

export default class FilterPopover extends Component {
  constructor(props) {
    super();

    this.state = {
      search: null,
      searching: false,
      searchActive: false,
    };

    this.mobileInputRef = React.createRef();
  }

  render({ data, maxHeight }) {
    let search;
    let filteredData = data;

    if (this.state.searching) {
      filteredData = data.map(function (datum) {
        return {
          name: datum.name,
          data: datum.data.search ? datum.data.search(this.state.search) : [],
        };
      }, this);
    }

    if (this.props.searchable) {
      let empty = !this.state.search || this.state.search.trim() === '';

      search = (
        <div class={'filter-popover__search' + (empty ? ' filter-popover__search--empty' : '')}>
          <div className="filter-popover__search__input">
            <input
              type="text"
              placeholder="Search…"
              value={this.state.search}
              onInput={this.handleSearchInput}
            />
            <button
              className="filter-popover__search__clear"
              type="button"
              aria-label={`Clear search`}
              onClick={this.handleSearchClearClick}
            >
              <Icon id="close" />
            </button>
          </div>
        </div>
      );
    }

    let className = 'filter-popover';
    if (this.props.searchable && Array.isArray(filteredData)) {
      className += ' filter-popover--hasheader';
    }

    if (this.props.modifier) {
      className += ` filter-popover--${this.props.modifier}`;
    }

    let activeTabIndex = 0;
    filteredData.forEach((datum, i) => {
      if (datum.active) {
        activeTabIndex = i;
      }
    });

    return (
      <div class={className} style={{ height: maxHeight }}>
        <TabsContainer activeTabIndex={activeTabIndex} handleActivate={this.handleTabActivate}>
          <div className="filter-popover__header">
            {this.renderMobileHeader(search)}
            <div className="filter-popover__header__tablet">{search}</div>
            {this.renderTablist(filteredData)}
          </div>
          {this.renderPopoverBody(filteredData)}
        </TabsContainer>
      </div>
    );
  }

  renderMobileHeader(search) {
    return (
      <>
        <div className="filter-popover__header__mobile">
          <div className="filter-popover__header__mobile__close">
            {this.props.onClose && (
              <button type="button" onClick={this.handleCloseClick} aria-label="Close">
                <Icon id="close" />
              </button>
            )}
          </div>
          <div className="filter-popover__header__mobile__input">
            <label for="filter-popover-input">{this.props.label}</label>
            <input
              id={`filter-popover-input`}
              ref={this.mobileInputRef}
              type="text"
              value={
                this.state.searchActive
                  ? this.state.search
                  : this.props.current && this.props.current.model.name
              }
              onInput={this.handleSearchInput}
              readOnly={!this.state.searchActive}
            />
          </div>
          {this.props.searchable && (
            <div className="filter-popover__header__mobile__trigger">
              {!this.state.searchActive ? (
                <button type="button" aria-label="Search" onClick={this.handleSearchTriggerClick}>
                  <Icon id="search" />
                </button>
              ) : (
                <button
                  type="button"
                  aria-label="Cancel search"
                  onClick={this.handleSearchTriggerClick}
                >
                  <Icon id="close" />
                </button>
              )}
            </div>
          )}
          {/* {this.state.searchActive && (
            <div className="filter-popover__header__mobile__search">{search}</div>
          )} */}
        </div>
      </>
    );
  }

  renderTablist(data) {
    if (!Array.isArray(data)) {
      return;
    }

    return (
      <div className="filter-popover__header__tabs">
        <Tablist>
          {data.map(function (datum) {
            return <Tab modifier={this.props.modifier}>{datum.name}</Tab>;
          }, this)}
        </Tablist>
      </div>
    );
  }

  renderPopoverBody(data) {
    let children;

    if (Array.isArray(data)) {
      children = data.map(function (datum) {
        return (
          <Tabpanel>
            <Tree
              current={this.props.current}
              exclude={this.props.disabled || []}
              invalid={this.props.invalid || []}
              data={datum.data}
              flatten={this.state.searching}
              showCounts={datum.showCounts ? true : null}
              emptyMessage="No results"
              type={this.props.modifier}
              noWrap={this.props.noWrap}
              handleClick={this.handleTreeClick}
            />
          </Tabpanel>
        );
      }, this);
    } else {
      children = [
        <Tree
          current={this.props.current}
          exclude={this.props.disabled || []}
          invalid={this.props.invalid || []}
          data={data}
          flatten={this.state.searching}
          reverse={this.props.reverse}
          showIcons={this.props.showIcons}
          type={this.props.modifier}
          noWrap={this.props.noWrap}
          inline={this.props.inline}
          handleClick={this.handleTreeClick}
        />,
      ];
    }

    return (
      <div className="filter-popover__body" ref={ref(this, 'refPopoverBody')}>
        {children}
      </div>
    );
  }

  componentDidUpdate() {
    if (!this.focusSearchInput && !this.focusMobileSearchInput) {
      return;
    }

    const input = this.focusMobileSearchInput
      ? this.mobileInputRef.current
      : this.base.querySelector('input');

    if (input) {
      input.focus();

      if (this.focusMobileSearchInput) {
        input.setSelectionRange(0, input.value.length);
      }
    }

    this.focusSearchInput = this.focusMobileSearchInput = false;
  }

  handleSearchInput = (event) => {
    let value = event.target.value || '';

    this.setState({
      search: value,
      searching: value.trim() !== '',
    });
  };

  handleTreeClick = (item) => {
    this.props.onTreeClick(item);
  };

  handleSearchClearClick = () => {
    this.setState({
      search: '',
      searching: false,
      searchActive: false,
    });
  };

  handleTabActivate = () => {
    if (!this.refPopoverBody) {
      return;
    }

    this.refPopoverBody.scrollTop = 0;
  };

  handleSearchTriggerClick = () => {
    const searchActive = !this.state.searchActive;
    const searching = searchActive;
    const search = searchActive ? this.props.current.model.name : null;

    this.setState({
      searchActive: searchActive,
      searching: searching,
      search: search,
    });

    if (searchActive) {
      this.focusMobileSearchInput = true;
    }
  };

  handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
}
